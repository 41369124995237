import { ApolloClient, from } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import graphqlLink from "./link";
import authLink from "./refreshTokenLink";
import { cache } from "../cache";

const client = new ApolloClient({
  link: from([new RetryLink(), authLink, graphqlLink]),
  cache,
});

export default client;
