import { useChatsQuery, ChatsQueryVariables, Scalars } from '../graphql/schema'
import { useCallback, useState } from 'react'
import { CHAT_LIST_SLICE } from '../config'

interface ChatFilters {
  offsetMessageId?: number
  tagId?: number
  unread?: boolean
  operatorsId?: number
}

const generateFilterVariables = ({ offsetMessageId, tagId, unread, operatorsId }: ChatFilters): ChatsQueryVariables => {
  const variables: ChatsQueryVariables = {
    limit: CHAT_LIST_SLICE,
  }

  const where: ChatsQueryVariables['where'] = {}

  // filter by tag
  if (tagId) {
    where.tags = {
      tag_id: {
        _eq: tagId
      }
    }
  }

  // pagination
  where.last_message_id = {
    _is_null: false
  }
  if (offsetMessageId) {
    where.last_message_id._lt = offsetMessageId
  }

  // unread only
  if (unread !== undefined) {
    where.unread = {
      _eq: unread,
    };
  }

  // only my messages
  if (operatorsId !== undefined) {
     where.operators = {
        operator_id: {
          _eq: operatorsId
       }
    }
  };

  variables.where = where
  return variables
}

const useFilteredChatsQuery = (filters: ChatFilters) => {
  const result = useChatsQuery({
    variables: generateFilterVariables(filters)
  })

  const fetchMore = result.fetchMore

  const filteredFetchMore = useCallback((nextOffsetMessageId?: number) => {
    if (!nextOffsetMessageId) return

    return fetchMore({
      variables: generateFilterVariables({
        tagId: filters.tagId,
        offsetMessageId: nextOffsetMessageId,
        unread: filters.unread,
        operatorsId: filters.operatorsId
      })
    })
  }, [filters.tagId, filters.unread, filters.operatorsId, fetchMore]);

  const filteredFullyRefetch = useCallback(
    () =>
        result.refetch(
            generateFilterVariables({
                tagId: filters.tagId,
                offsetMessageId: undefined,
                unread: filters.unread,
                operatorsId: filters.operatorsId
            }),
        ),
    [filters.tagId, filters.unread, filters.operatorsId],
);

  return {
    ...result,
    fetchMore: filteredFetchMore,
    refetchWithFilter: filteredFullyRefetch
  }
}

export default useFilteredChatsQuery