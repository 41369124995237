import React, { FC, memo, useCallback, ChangeEvent } from 'react';

import buildClassName from '../../util/buildClassName';

import Spinner from './Spinner';

import './Checkbox.scss';

type OwnProps = {
  id?: string;
  name?: string;
  value?: string;
  label: string;
  subLabel?: string;
  checked: boolean;
  disabled?: boolean;
  round?: boolean;
  blocking?: boolean;
  isLoading?: boolean;
  withCheckedCallback?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onCheck?: (isChecked: boolean) => void;
  classes?: CheckBoxClasses;
};

type CheckBoxClasses = {
  container?: string;
  input?: string;
  label?: string;
}

const Checkbox: FC<OwnProps> = ({
  id,
  name,
  value,
  label,
  subLabel,
  checked,
  disabled,
  round,
  blocking,
  isLoading,
  onChange,
  onCheck,
  classes
}) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }

    if (onCheck) {
      onCheck(event.currentTarget.checked);
    }
  }, [onChange, onCheck]);

  const className = buildClassName(
    'Checkbox',
    disabled && 'disabled',
    round && 'round',
    isLoading && 'loading',
    blocking && 'blocking',
    classes?.container && classes.container
  );
  const checkboxClassName = buildClassName(
    "Checkbox-main",
    classes?.input && classes.input
  )
  const labelClassName = buildClassName(
    "label",
    classes?.label && classes.label
  )

  return (
    <label className={className}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <div className={checkboxClassName}>
        <span className={labelClassName}>{label}</span>
        {subLabel && <span className="subLabel">{subLabel}</span>}
      </div>
      {isLoading && <Spinner />}
    </label>
  );
};

export default memo(Checkbox);
