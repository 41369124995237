import { useCallback } from "react";
import {
  useSearchMessagesAndUsersQuery,
  SearchMessagesAndUsersQueryVariables,
} from "../graphql/schema";

interface ChatFilters {
  offsetMessageId?: number;
  searchQuery: string;
}

const generateFilterVariables = ({
  offsetMessageId,
  searchQuery,
}: ChatFilters): SearchMessagesAndUsersQueryVariables => {
  const variables: SearchMessagesAndUsersQueryVariables = {
    query: searchQuery,
  };

  const where: SearchMessagesAndUsersQueryVariables["where"] = {};


  // pagination
  where.message_id = {
    _is_null: false,
  };
  if (offsetMessageId) {
    where.message_id._lt = offsetMessageId;
  }

  variables.where = where;
  return variables;
};

const useGlobalSearchQuery = (filters: ChatFilters) => {

  const result = useSearchMessagesAndUsersQuery({
    variables: generateFilterVariables(filters),
  });

  const fetchMore = result.fetchMore;

  const filteredFetchMore = useCallback(
    (nextOffsetMessageId?: number) => {
      console.log("fetching more", nextOffsetMessageId);
      return fetchMore({
        variables: generateFilterVariables({
          searchQuery: filters.searchQuery,
          offsetMessageId: nextOffsetMessageId,
        }),
      });
    },
    [filters.searchQuery, fetchMore]
  );

  return {
    ...result,
    fetchMore: filteredFetchMore,
  };
};

export default useGlobalSearchQuery;
