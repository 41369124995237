import React, { FC, memo } from 'react';

import { ApiMessageOutgoingStatus } from '../../api/types';

import './MessageOutgoingStatus.scss';

type OwnProps = {
  status: ApiMessageOutgoingStatus;
};

const MessageOutgoingStatus: FC<OwnProps> = ({ status }) => {
  return (
    <div className="MessageOutgoingStatus">
      {() => (
        <i className={`icon-message-${status}`} />
      )}
    </div>
  );
};

export default memo(MessageOutgoingStatus);
