import { useLayoutEffect, useState } from 'react';

import { ApiMessage } from '../api/types';

import { DEBUG } from '../config';
import { isWebpSupported } from '../util/environment';
import { getMessageMediaThumbDataUri } from '../global/helpers';
import { EMPTY_IMAGE_DATA_URI, webpToPngBase64 } from '../util/webpToPng';

export default function useWebpThumbnail(message?: ApiMessage) {
  const [thumbDataUri, setThumbDataUri] = useState<string|undefined>('');
  const sticker = message?.content?.sticker;
  const shouldDecodeThumbnail = thumbDataUri && sticker && !isWebpSupported() && thumbDataUri.includes('image/webp');
  const [thumbnailDecoded, setThumbnailDecoded] = useState(EMPTY_IMAGE_DATA_URI);
  const messageId = message?.id;
  useLayoutEffect(() => {
    if (!shouldDecodeThumbnail || !message) {
      return;
    }
    getMessageMediaThumbDataUri(message)
        .then(setThumbDataUri)
        .then(() =>webpToPngBase64(`b64-${messageId}`, thumbDataUri!))
        .then(setThumbnailDecoded)
        .catch((err) => {
          if (DEBUG) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        });
  }, [message, messageId, shouldDecodeThumbnail, thumbDataUri])

  return shouldDecodeThumbnail ? thumbnailDecoded : thumbDataUri || EMPTY_IMAGE_DATA_URI;
}
