import React, {
  FC, memo, useCallback, useEffect, useState, useMemo
} from 'react';

import { GlobalActions } from '../../global/types';
import { ApiChat } from '../../api/types';
import { IAnchorPosition } from '../../types';

import { IS_MOBILE_SCREEN } from '../../util/environment';
import { disableScrolling, enableScrolling } from '../../util/scrollLock';
//import { selectChat } from '../../modules/selectors';
import { pick } from '../../util/iteratees';
import { isUserId, getCanDeleteChat } from '../../global/helpers';
import useShowTransition from '../../hooks/useShowTransition';
import useLang from '../../hooks/useLang';

import Portal from '../ui/Portal';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';
import DeleteChatModal from '../common/DeleteChatModal';
import {
  useChatFoldersQuery,
  useAddTagMutation,
  useRemoveTagMutation,
  useChatTagsQuery,
  ChatTagsDocument
} from '../../graphql/schema';

import './HeaderMenuContainer.scss';

//type DispatchProps = Pick<GlobalActions, 'updateChatMutedState' | 'enterMessageSelectMode'>;

export type OwnProps = {
  chatId: number;
  isOpen: boolean;
  anchor: IAnchorPosition;
  onClose: () => void;
  onCloseAnimationEnd: () => void;
};

const HeaderMenuContainer: FC<OwnProps> = ({
  chatId,
  isOpen,
  anchor,
  onClose,
  onCloseAnimationEnd,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const { data: chatTagsData } = useChatTagsQuery({ variables: { id: chatId } });

  const chatTagIds = useMemo(() => {
    return chatTagsData?.user?.tags?.map(tag => tag.tag_id) || [];
  }, [chatTagsData?.user?.tags])

  const [addTag] = useAddTagMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      addUserTagRelation: {
        __typename: 'user_tag_relations',
        user_id: variables.userId,
        tag_id: variables.tagId
      }
    }),
    // refetchQueries: [{ query: ChatTagsDocument, variables: { id: chatId } }]
  });
  const [removeTag] = useRemoveTagMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      removeUserTagRelation: {
        __typename: 'user_tag_relations',
        user_id: variables.userId,
        tag_id: variables.tagId
      }
    }),
  });

  const { x, y } = anchor;

  const { data } = useChatFoldersQuery()
  const folderTags = data?.tags ?? []

  useShowTransition(isOpen, onCloseAnimationEnd, undefined, false);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    disableScrolling();

    return enableScrolling;
  }, []);

  return (
    <Portal>
      <div className="HeaderMenuContainer">
        <Menu
          isOpen={isMenuOpen}
          positionX="right"
          style={{
            left: `${x}px`,
            top: `${y}px`
          }}
          onClose={closeMenu}
        >
          {folderTags.map(tag => {
            const active = chatTagIds.includes(tag.tag_id)
            return (
              <MenuItem
                key={tag.tag_id}
                // onClick={handleSearch}
                className={active ? 'selected' : undefined}
                onClick={() => {
                  active
                    ? removeTag({ variables: { userId: chatId, tagId: tag.tag_id } })
                    : addTag({ variables: { userId: chatId, tagId: tag.tag_id } })
                }}
              >
                {tag.tag_name}
              </MenuItem>
            )
          }
          )}
        </Menu>
      </div>
    </Portal>
  );
};

export default memo(HeaderMenuContainer)
