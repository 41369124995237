import React, { FC, useMemo } from 'react';

import { ApiMessage } from '../../api/types';

import { DEBUG, INACTIVE_MARKER, PAGE_TITLE, } from '../../config';
// import {
//   selectChatMessage,
//   selectCountNotMutedUnread,
//   selectIsForwardModalOpen,
//   selectIsRightColumnShown,
// } from '../../modules/selectors';
import useBackgroundMode from '../../hooks/useBackgroundMode';

import LeftColumn from '../left/LeftColumn';
import MiddleColumn from '../middle/MiddleColumn';
import RightColumn from '../right/RightColumn';
// import MediaViewer from '../mediaViewer/MediaViewer';
// import AudioPlayer from '../middle/AudioPlayer';
// import Notifications from './Notifications.async';
// import Errors from './Errors.async';
// import ForwardPicker from './ForwardPicker.async';
// import HistoryCalendar from './HistoryCalendar.async';
import useSyncChatIdVarWithSearchParams from '../../hooks/useSyncChatIdVarWithSearchParams';
import './Main.scss';
import MediaViewer from '../mediaViewer/MediaViewer';
import { useReactiveVar } from "@apollo/client";
import { currentChatIdVar, mediaViewerVar } from "../../cache";
import buildClassName from '../../util/buildClassName';
import { useChatQuery } from '../../graphql/schema';
import windowSize from '../../util/windowSize';
import useKorostelEvents from '../../hooks/useKorostelEvents';

import useListenToNewMessages from '../../hooks/useListenToNewMessages';
import useListenToDeletedMessages from '../../hooks/useListenToDeletedMessages';
import { useUpdateMessageSubscription, useNewChatTagSubscription, useDeleteChatTagSubscription } from '../../graphql/schema';

export interface OwnProps {
  isMobile?: boolean;
}
type StateProps = {
  animationLevel?: number;
  lastSyncTime?: number;
  isLeftColumnShown?: boolean;
  isRightColumnShown?: boolean;
  isForwardModalOpen?: boolean;
  hasNotifications?: boolean;
  hasErrors?: boolean;
  audioMessage?: ApiMessage;
  safeLinkModalUrl?: string;
  isHistoryCalendarOpen?: boolean;
};
const { width } = windowSize.get();
// type DispatchProps = Pick<GlobalActions, (
//   'loadAnimatedEmojis' | 'loadNotificationSettings' | 'loadNotificationExceptions' | 'updateIsOnline'
// )>;

// const ANIMATION_DURATION = 350;
const NOTIFICATION_INTERVAL = 1000;

// let rightColumnAnimationTimeout: number | undefined;
let notificationInterval: number | undefined;

let DEBUG_isLogged = false;

const Main: FC<OwnProps & StateProps> = ({
  isMobile,
  isLeftColumnShown,
  // lastSyncTime,
  // isRightColumnShown,
  // isForwardModalOpen,
  // animationLevel,
  // hasNotifications,
  // hasErrors,
  // audioMessage,
  // safeLinkModalUrl,
  // isHistoryCalendarOpen,
  // loadAnimatedEmojis,
  // loadNotificationSettings,
  // loadNotificationExceptions,
  // updateIsOnline,
}) => {
  if (DEBUG && !DEBUG_isLogged) {
    DEBUG_isLogged = true;
    // eslint-disable-next-line no-console
    console.log('>>> RENDER MAIN');
  }

  /* const isLeftColumnShown = useReactiveVar(isLeftColumnShownVar)
   const isRightColumnShown = useReactiveVar(isLeftColumnShownVar)*/

  // Initial API calls
  // useEffect(() => {
  //   if (lastSyncTime) {
  //     updateIsOnline(true);
  //     loadAnimatedEmojis();
  //     loadNotificationSettings();
  //     loadNotificationExceptions();
  //   }
  // }, [lastSyncTime, loadAnimatedEmojis, loadNotificationExceptions, loadNotificationSettings, updateIsOnline]);

  /* const {
     transitionClassNames: middleColumnTransitionClassNames,
   } = useShowTransition(!isLeftColumnShown, undefined, true);
 */
  /*const {
    transitionClassNames: rightColumnTransitionClassNames,
  } = useShowTransition(isRightColumnShown, undefined, true);
*/
  const chatId = useReactiveVar(currentChatIdVar);
  if (chatId !== 0) {
    window.parent.postMessage({ "chatId": chatId }, "*");
  }

  const getClassName = () => {

    if (chatId !== 0 && isMobile && width < 925) {
      return 'middle-column-open'
    }
    return ''
  }
  /*
    useEffect(() => {
      // For animating Symbol Menu on mobile
      document.body.classList.toggle('is-middle-column-open', className.includes('middle-column-open'));
      // For animating components in portals (i.e. Notification)
      document.body.classList.toggle('is-right-column-shown', className.includes('right-column-open'));
    }, [className]);
  */
  // Add `body` classes when toggling right column
  // useEffect(() => {
  //   if (animationLevel > 0) {
  //     document.body.classList.add('animating-right-column');
  //     dispatchHeavyAnimationEvent(ANIMATION_DURATION + ANIMATION_END_DELAY);

  //     if (rightColumnAnimationTimeout) {
  //       clearTimeout(rightColumnAnimationTimeout);
  //       rightColumnAnimationTimeout = undefined;
  //     }

  //     rightColumnAnimationTimeout = window.setTimeout(() => {
  //       document.body.classList.remove('animating-right-column');
  //       rightColumnAnimationTimeout = undefined;
  //     }, ANIMATION_DURATION + ANIMATION_END_DELAY);
  //   }
  // }, [animationLevel, isRightColumnShown]);

  // useBackgroundMode(() => {
  //   updateIsOnline(false);
  // }, () => {
  //   updateIsOnline(true);
  // });

  // useEffect(() => {
  //   function handleUnload() {
  //     updateIsOnline(false);
  //   }

  //   window.addEventListener('beforeunload', handleUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleUnload);
  //   };
  // }, [updateIsOnline]);

  // sync chat id with search params
  useSyncChatIdVarWithSearchParams();
  // listen to events from Korostel and update chat id
  useKorostelEvents();

  useNewChatTagSubscription();
  useDeleteChatTagSubscription();

  useListenToNewMessages();
  useListenToDeletedMessages();
  useUpdateMessageSubscription();

  // Browser tab indicators
  useBackgroundMode(() => {
    // const initialUnread = 0 //selectCountNotMutedUnread(getGlobal());
    let index = 0;

    clearInterval(notificationInterval);
    notificationInterval = window.setInterval(() => {
      if (document.title.includes(INACTIVE_MARKER)) {
        updateIcon(false);
        return;
      }

      if (index % 2 === 0) {
        const newUnread = 0 //selectCountNotMutedUnread(getGlobal()) - initialUnread;
        if (newUnread > 0) {
          document.title = `${newUnread} notification${newUnread > 1 ? 's' : ''}`;
          updateIcon(true);
        }
      } else {
        document.title = PAGE_TITLE;
        updateIcon(false);
      }

      index++;
    }, NOTIFICATION_INTERVAL);
  }, () => {
    clearInterval(notificationInterval);
    notificationInterval = undefined;

    if (!document.title.includes(INACTIVE_MARKER)) {
      document.title = PAGE_TITLE;
    }

    updateIcon(false);
  });

  function stopEvent(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
  }
  const mediaViewerValue = useReactiveVar(mediaViewerVar)
  const isMediaViewerOpen = useMemo<boolean>(() => {
    return !!mediaViewerValue.messageId
  }, [mediaViewerValue])

  return (
    <div id="Main" className={getClassName()} onDrop={stopEvent} onDragOver={stopEvent}>
      <LeftColumn />
      <MiddleColumn />
      <RightColumn />
      <MediaViewer isOpen={isMediaViewerOpen} />
      {/*<ForwardPicker isOpen={isForwardModalOpen} />
      <Notifications isOpen={hasNotifications} />
      <Errors isOpen={hasErrors} />
      {audioMessage && <AudioPlayer key={audioMessage.id} message={audioMessage} noUi />}
      <SafeLinkModal url={safeLinkModalUrl} />
      <HistoryCalendar isOpen={isHistoryCalendarOpen} /> */}
    </div>
  );
};

function updateIcon(asUnread: boolean) {
  document.querySelectorAll<HTMLLinkElement>('link[rel="icon"]')
    .forEach((link) => {
      if (asUnread) {
        if (!link.href.includes('favicon-unread')) {
          link.href = link.href.replace('favicon', 'favicon-unread');
        }
      } else {
        link.href = link.href.replace('favicon-unread', 'favicon');
      }
    });
}


export default Main;
