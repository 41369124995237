
import React, {
  memo,
  useCallback,
  useMemo,
    FC
} from 'react';

import type { ApiMessage, ApiPreparedMedia } from '../../api/types';

import { IS_SINGLE_COLUMN_LAYOUT } from '../../util/environment';
import { getMessageMediaHash } from '../../global/helpers';
import useLang from '../../hooks/useLang';
import useMediaWithLoadProgress from '../../hooks/useMediaWithLoadProgress';

import Button from '../ui/Button';
import DropdownMenu from '../ui/DropdownMenu';
import MenuItem from '../ui/MenuItem';
import ProgressSpinner from '../ui/ProgressSpinner';

import './MediaViewerActions.scss';

type StateProps = {
  isDownloading: boolean;
};

type OwnProps = {
  mediaData?: ApiPreparedMedia;
  isVideo: boolean;
  zoomLevelChange: number;
  message?: ApiMessage;
  fileName?: string;
  onCloseMediaViewer: NoneToVoidFunction;
  setZoomLevelChange: (change: number) => void;
};

const MediaViewerActions: FC<OwnProps & StateProps> = ({
  mediaData,
  isVideo,
  message,
  fileName,
  isDownloading,
  onCloseMediaViewer,
  zoomLevelChange,
  setZoomLevelChange,
}) => {


  const { loadProgress: downloadProgress } = useMediaWithLoadProgress(
    message && getMessageMediaHash(message, 'download'),
    !isDownloading,
  );

  const handleDownloadClick = useCallback(() => {
      console.debug('[Media Viewer Actions][Component] handleDownloadClick handled')
   /* if (isDownloading) {
      cancelMessageMediaDownload({ message: message! });
    } else {
      downloadMessageMedia({ message: message! });
    }*/
  }, []);

  const handleZoomOut = useCallback(() => {
    const change = zoomLevelChange < 0 ? zoomLevelChange : 0;
    setZoomLevelChange(change - 1);
  }, [setZoomLevelChange, zoomLevelChange]);

  const handleZoomIn = useCallback(() => {
    const change = zoomLevelChange > 0 ? zoomLevelChange : 0;
    setZoomLevelChange(change + 1);
  }, [setZoomLevelChange, zoomLevelChange]);

  const lang = useLang();

  const MenuButton: FC<{ onTrigger: () => void; isOpen?: boolean }> = useMemo(() => {
    return ({ onTrigger, isOpen }) => (
      <Button
        round
        size="smaller"
        color="translucent"
        className={isOpen ? 'active' : undefined}
        onClick={onTrigger}
        ariaLabel="More actions"
      >
        <i className="icon-more" />
      </Button>
    );
  }, []);

  function renderDownloadButton() {
       return isVideo ? (
      <Button
        round
        size="smaller"
        color="translucent-white"
        ariaLabel={lang('AccActionDownload')}
        onClick={handleDownloadClick}
      >
        {isDownloading ? (
          <ProgressSpinner progress={downloadProgress} size="s" onClick={handleDownloadClick} />
        ) : (
          <i className="icon-download" />
        )}
      </Button>
    ) : (
      <Button
        href={String(mediaData)}
        download={fileName}
        round
        size="smaller"
        color="translucent-white"
        ariaLabel={lang('AccActionDownload')}
      >
        <i className="icon-download" />
      </Button>
    );
  }

  if (IS_SINGLE_COLUMN_LAYOUT) {
   return (
      <div className="MediaViewerActions-mobile">
        <DropdownMenu
          hasMenu={true}
          trigger={MenuButton}
          positionX="right"
        >
         {/* {!isAvatar && (
            <MenuItem
              icon="forward"
              onClick={onForward}
            >
              {lang('Forward')}
            </MenuItem>
          )}*/}
          {isVideo ? (
            <MenuItem
              icon={isDownloading ? 'close' : 'download'}
              onClick={handleDownloadClick}
            >
              {isDownloading ? `${Math.round(downloadProgress * 100)}% Downloading...` : 'Download'}
            </MenuItem>
          ) : (
            <MenuItem
              icon="download"
              href={String(mediaData)}
              download={fileName}
            >
              {lang('AccActionDownload')}
            </MenuItem>
          )}
          {/*{canReport && (
            <MenuItem
              icon="flag"
              onClick={onReport}
            >
              {lang('ReportPeer.Report')}
            </MenuItem>
          )}*/}
        </DropdownMenu>
        {isDownloading && <ProgressSpinner progress={downloadProgress} size="s" noCross />}
      </div>
    );
  }

  return (
    <div className="MediaViewerActions">
     {/* TODO:Forward
     {!isAvatar && !isProtected && (
        <Button
          round
          size="smaller"
          color="translucent-white"
          ariaLabel={lang('Forward')}
          onClick={onForward}
        >
          <i className="icon-forward" />
        </Button>
      )}*/}
      {renderDownloadButton()}
      <Button
        round
        size="smaller"
        color="translucent-white"
        ariaLabel={lang('MediaZoomOut')}
        onClick={handleZoomOut}
      >
        <i className="icon-zoom-out" />
      </Button>
      <Button
        round
        size="smaller"
        color="translucent-white"
        ariaLabel={lang('MediaZoomIn')}
        onClick={handleZoomIn}
      >
        <i className="icon-zoom-in" />
      </Button>
      <Button
        round
        size="smaller"
        color="translucent-white"
        ariaLabel={lang('Close')}
        onClick={onCloseMediaViewer}
      >
        <i className="icon-close" />
      </Button>
    </div>
  );
};

export default memo<OwnProps>(({ ...props }) => {
    const isDownloading = false; // TODO: Downloading
    return <MediaViewerActions {...props} isDownloading={isDownloading}/>
});
