/* eslint-disable import/no-anonymous-default-export */
import { useCallback, useEffect } from 'react';
import { ApiMessage } from '../../../../api/types';
import { EDITABLE_INPUT_ID } from '../../../../config';
import parseMessageInput from '../helpers/parseMessageInput';
import getMessageTextAsHtml from '../helpers/getMessageTextAsHtml';
import focusEditableElement from '../../../../util/focusEditableElement';
import { hasMessageMedia } from '../../../../global/helpers';
import { EditMessageParams } from '../../../../hooks/useEditMessage';

export default (
  htmlRef: { current: string },
  setHtml: (html: string) => void,
  editedMessage: ApiMessage | undefined,
  resetComposer: () => void,
  openDeleteModal: () => void,
  editMessage: (params: EditMessageParams) => void,
  chatId: number
) => {
  // TODO useOnChange
  // Handle editing message
  useEffect(() => {
    if (!editedMessage) {
      setHtml('');
      return;
    }

    setHtml(getMessageTextAsHtml(editedMessage.content.text));

    requestAnimationFrame(() => {
      const messageInput = document.getElementById(EDITABLE_INPUT_ID)!;
      focusEditableElement(messageInput, true);
    });
  }, [editedMessage, setHtml]);

  const handleEditComplete = useCallback(() => {
    const formattedText = parseMessageInput(htmlRef.current!);
    if (!editedMessage) {
      return;
    }

    if (!formattedText.text && !hasMessageMedia(editedMessage)) {
      openDeleteModal();
      return;
    }


    editMessage({
      messageId: editedMessage.id,
      formattedText,
      chatId,
      isCaption: !!editedMessage.content.photo
    });

    resetComposer();
  }, [editMessage, editedMessage, htmlRef, openDeleteModal, resetComposer, chatId]);

  return handleEditComplete;
};
