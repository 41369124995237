import {
    HttpLink,
    split
} from "@apollo/client";
import { createClient } from 'graphql-ws'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'

import { AUTH_STORAGE_KEY } from '../config'
import { getRefreshedAccessTokenPromise } from './refreshTokenLink';

import { env } from '../env'

if (!env.REACT_APP_DOMAIN) {
    throw new Error('REACT_APP_DOMAIN env required')
}

const REACT_APP_BOTAPI_GRAPHQL_URL = (env.BACKEND_API_URL ? env.BACKEND_API_URL : env.REACT_APP_DOMAIN) + '/api/graphql/'
const REACT_APP_HASURA_GRAPHQL_URL = env.REACT_APP_DOMAIN + '/hasura/v1/graphql'


// converts http or https link into ws or wss accordingly
const REACT_APP_BOTAPI_GRAPHQL_WS_URL = REACT_APP_BOTAPI_GRAPHQL_URL.replace(/(http)(s)?:\/\//, 'ws$2://')

// We have two separate backends:
// Hasura and Bot Api (for subscriptions and some ohter stuff)
// Apollo has to know where to send the request

const graphqlHasura = new HttpLink({ uri: REACT_APP_HASURA_GRAPHQL_URL })
const graphqlBotApi = createUploadLink({ uri: REACT_APP_BOTAPI_GRAPHQL_URL })

let activeSocket : any, timedOut : any
const graphqlBotSubscriptions = new GraphQLWsLink(
    createClient({
        url: REACT_APP_BOTAPI_GRAPHQL_WS_URL,
        retryAttempts: Infinity,
        shouldRetry: () => true,
        keepAlive: 30_000, // ping server every 30 seconds
        connectionParams: () => {
            const token = localStorage.getItem(AUTH_STORAGE_KEY)
            return {
                Authorization: token ? `Bearer ${token}` : ''
            }
        },
        on: {
            connected: (socket) => (activeSocket = socket),
            ping: (received) => {
                if (!received) // sent
                    timedOut = setTimeout(() => {
                        if (activeSocket.readyState === WebSocket.OPEN)
                            activeSocket.close(4408, 'Request Timeout');
                    }, 10_000); // wait 10 seconds for the pong and then close the connection
            },
            pong: (received) => {
                if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
            },
            closed: (e) => {
                getRefreshedAccessTokenPromise().catch((error) => {
                    console.error('refresh accesss token rejected with error: ' + error);
                });
            },
        },
}))

const graphqlBotApiLink = split(
    ({ query, operationName }) => {
        const definition = getMainDefinition(query)
        const useWebsocket = definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        // console.log(`operation: ${operationName} is sent over ${useWebsocket ? 'websocket' : 'http'}`)
        return useWebsocket
    }, // condition function
    graphqlBotSubscriptions, // uses this if condition returns true
    graphqlBotApi // uses this if condition returns false
)

// list of operations that are sent to the Bot Api
const botApiOperations = [
    'NewMessage',
    'SendMessages',
    'NewChatTag',
    'DeleteChatTag',
    'DeleteMessage',
    'EditMessage',
    'UpdateMessage',
    'RemoveMessages',
    'AddTag',
    'RemoveTag',
    'stickerSet',
    'SendSticker',
    'AvailableStickers'
]

const graphqlLink = split(
    ({ operationName }) => {
        const toBotApi = botApiOperations.includes(operationName)
        // console.log(`operation: ${operationName} goes to ${toBotApi ? 'Bot Api' : 'Hasura'}`)
        return toBotApi
    },
    graphqlBotApiLink,
    graphqlHasura
)

export default graphqlLink
