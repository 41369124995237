import { FC, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';

type OwnProps = {
  containerId?: string;
  className?: string;
  children: any;
};

const Portal: FC<OwnProps> = ({ containerId, className, children }) => {
  // const elementRef = useRef(document.createElement('div'));

  // useLayoutEffect(() => {
  //   const container = document.querySelector<HTMLDivElement>(containerId || '#portals');
  //   if (!container) {
  //     return undefined;
  //   }

  //   const element = elementRef.current;
  //   if (className) {
  //     element.classList.add(className);
  //   }

  //   container.appendChild(element);

  //   return () => {
  //     // ReactDOM.render(null, element);
  //     container.removeChild(element);
  //   };
  // }, [className, containerId]);

  const root = document.getElementById('portals')

  if (!root) return null

  return ReactDOM.createPortal(
    children,
    root
  );
};

export default Portal;
