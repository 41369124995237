import { InMemoryCache, makeVar, FieldMergeFunction, Reference, ReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'
import { activeDownloadsData, MediaViewerData, ThemeKey } from './types';
import { Message } from 'telegram-typings'
import { AUTH_STORAGE_KEY, AUTH_REFRESH_STORAGE_KEY, DEFAULT_PLAYBACK_RATE, DEFAULT_VOLUME } from './config'
import { addTagToChat } from './graphql/typePolicies/addTagToChat';
import { removeTagFromChat } from './graphql/typePolicies/removeTagFromChat';
import parseJWT from './util/parseJWT';
import { addChatToFolder } from './graphql/typePolicies/addChatToFolder';
import { removeChatFromFolder } from './graphql/typePolicies/removeChatFromFolder';
import {
  ApiUser,
  ApiChat,
  ApiMessage,
  ApiFormattedText,
  ApiChatFolder,
  ApiPhotoSize,
} from "./api/types";
import {
  StrictTypedTypePolicies,
  Messages,
  MessagesQueryVariables,
  Users,
  ChatsQueryVariables,
} from "./graphql/schema";

/**
 * Updates localStorage on reactive var update
 */
const persistVar = (key: string, rVar: ReactiveVar<any>) => {
  const value = rVar();

    const onChange = (nextValue: any) => {
        if (nextValue) {
            Cookies.set(key, nextValue)
            localStorage.setItem(key, nextValue)
        } else {
            Cookies.remove(key)
            localStorage.removeItem(key)
        }
        rVar.onNextChange(onChange)
    }

  rVar.onNextChange(onChange);
  rVar(value);
};

// lastSyncTime,
export const isLeftColumnShownVar = makeVar<boolean>(false);
export const isRightColumnShownVar = makeVar<boolean>(false);

export const userVar = makeVar<ApiUser | undefined>(undefined);
export const isSavedMessagesVar = makeVar<boolean>(false);
export const areMessagesLoadedVar = makeVar<boolean>(false);
export const contactIdsVar = makeVar<number[]>([]);
export const usersByIdVar = makeVar<Record<number, ApiUser>>([]);
export const isMutedVar = makeVar<boolean>(false);
export const draftVar = makeVar<ApiFormattedText | undefined>(undefined);
export const chatFolderVar = makeVar<ApiChatFolder | undefined>(undefined);
export const currentChatIdVar = makeVar<number | undefined>(undefined);
export const currentMessageSearchIdVar = makeVar<number | undefined>(undefined);
export const onlyUnreadVar = makeVar<boolean>(false);
export const onlyMyMessagesVar = makeVar<boolean>(false);
export const currentUserIdVar = makeVar<number | undefined>(undefined);
export const lastSyncTimeVar = makeVar<number>(0);
export const editingId = makeVar<number>(0);
export const themeVar = makeVar<ThemeKey>("dark");
export const globalSearchQueryVar = makeVar<string>("");
export const selectModeActiveVar = makeVar<boolean>(false);
export const DEFAULT_MEDIA_VIEWER = {
  volume: DEFAULT_VOLUME,
  playbackRate: DEFAULT_PLAYBACK_RATE,
  isMuted: false,
};
export const mediaViewerVar = makeVar<MediaViewerData>(DEFAULT_MEDIA_VIEWER);
export const activeDownloadsVar = makeVar<activeDownloadsData>({
  byChatId: {},
});
export const authTokenVar = makeVar<string | undefined>(
  localStorage.getItem(AUTH_STORAGE_KEY) || undefined
);
persistVar(AUTH_STORAGE_KEY, authTokenVar);
export const refreshTokenVar = makeVar<string | undefined>(
  localStorage.getItem(AUTH_STORAGE_KEY) || undefined
);
persistVar(AUTH_REFRESH_STORAGE_KEY, refreshTokenVar);

// get user id from token every time token updates
const onTokenChange = (nextValue: string | undefined) => {

  const data = parseJWT(nextValue)
  currentUserIdVar(data?.hasura_oibot?.['x-hasura-user-id'] ? parseInt(data?.hasura_oibot?.['x-hasura-user-id']) : undefined)

  authTokenVar.onNextChange(onTokenChange)
}
onTokenChange(authTokenVar())



// isMediaViewerOpen,
// isForwardModalOpen,
// animationLevel,
// hasNotifications,
// hasErrors,
// audioMessage,
// safeLinkModalUrl,
// isHistoryCalendarOpen,
// loadAnimatedEmojis,
// loadNotificationSettings,
// loadNotificationExceptions,
// updateIsOnline,

export const balanceVar = makeVar<number>(0);

const CHAT_TYPE = "chatTypeBasicGroup";

const photoSizes: ApiPhotoSize["type"][] = ["s", "m", "x", "y", "z"];

const mergeMessages: FieldMergeFunction<(Messages | Reference)[]> = (
  existing = [],
  incoming,
  { toReference, readField, args, variables }
) => {
  const merged = existing ? existing.slice(0) : [];

  // Obtain a Set of all existing message IDs.
  const existingIdSet = new Set(
    merged.map((message) => readField("id", message))
  );

  // Remove incoming messages already present in the existing data.
  incoming = incoming.filter(
    (message) => !existingIdSet.has(readField("id", message))
  );

  const offsetMessageId = (args as MessagesQueryVariables).where?.message_id
    ?._lt;
  // Find the index of the message just before the incoming page of messages.
  const afterIndex = merged.findIndex(
    (message) => offsetMessageId === readField("id", message)
  );

  if (afterIndex >= 0) {
    // If we found afterIndex, insert incoming after that index.
    merged.splice(afterIndex + 1, 0, ...incoming);
  } else {
    // Otherwise insert incoming at the end of the existing data.
    merged.push(...incoming);
  }
  return merged;
};

const mergeChats: FieldMergeFunction<(Users | Reference)[]> = (
  existing,
  incoming,
  { toReference, readField, args, variables }
) => {
  const merged = existing ? existing.slice(0) : [];

  // Obtain a Set of all existing chat IDs.
  const existingIdSet = new Set(merged.map((chat) => readField("id", chat)));

  // Remove incoming chats already present in the existing data.
  incoming = incoming.filter(
    (chat) => !existingIdSet.has(readField("id", chat))
  );

  const offsetchatId = (args as ChatsQueryVariables).where?.last_message_id
    ?._lt;
  // Find the index of the chat just before the incoming page of chats.
  const afterIndex = merged.findIndex(
    (chat) => offsetchatId === readField("last_message_id", chat)
  );

  if (afterIndex >= 0) {
    // If we found afterIndex, insert incoming after that index.
    merged.splice(afterIndex + 1, 0, ...incoming);
  } else {
    // Otherwise insert incoming at the end of the existing data.
    merged.push(...incoming);
  }
  return merged;
};

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      // messages: offsetLimitPagination(['where', ['user_id', ['_eq']]]),
      messages: {
        // keyArgs helps apollo to decide how to separate messages into chats
        // args is a whole input object for the Query in hasura
        // I separate them by chatId which can be found in args.where.user_id._eq
        keyArgs: ["where", ["user_id", ["_eq"]]],
        merge: mergeMessages,
      },
      users: {
        keyArgs: ["where", ["tags", ["tag_id", ["_eq"]]]],
        merge: function (existing, incoming, options) {
          // if data comes from new page query we should merge it
          if (options?.variables?.where?.last_message_id) {
            return mergeChats(existing, incoming, options);
          }

          // if data comes from somewhere else
          // for example, we deleted a chat or changed chat order
          // we should overwrite the list
          return incoming
        },
      },
      users_by_pk: {
        read: (_, { args, variables, toReference }) => {

          const ref = toReference({
            __typename: 'users',
            user_id: parseInt(args?.user_id) || parseInt(variables?.id)
          })
          return ref
        }
      },
    }
  },
  Mutation: {
    fields: {
      sendMessages: {
        // keyArgs: ['input', ['chat_id', ['_eq']]],
      },
      removeMessages: {},
      addUserTagRelation: {
        // add tag to chat on mutation
        merge: (_, newChatTag, { cache, readField }) => {
          const userId: number | undefined = readField('user_id', newChatTag)
          const tagId: number | undefined = readField('tag_id', newChatTag)

          addTagToChat(userId, tagId, cache, readField)
          addChatToFolder(userId, tagId, cache, readField)
        }
      },
      removeUserTagRelation: {
        // remove tag from chat on mutation
        merge: (_, deleteChatTag, { cache, readField }) => {
          const userId: number | undefined = readField('user_id', deleteChatTag)
          const tagId: number | undefined = readField('tag_id', deleteChatTag)

          removeTagFromChat(userId, tagId, cache)
          removeChatFromFolder(userId, tagId, cache)
        }
      }
    }
  },
  Subscription: {
    fields: {
      newChatTag: {
        // add tag to chat on subscription
        merge: (_, newChatTag, { cache, readField }) => {
          const userId: number | undefined = readField('user_id', newChatTag)
          const tagId: number | undefined = readField('tag_id', newChatTag)

          addTagToChat(userId, tagId, cache, readField)
          addChatToFolder(userId, tagId, cache, readField)
        }
      },
      deleteChatTag: {
        // remove tag from chat on subscription
        merge: (_, deleteChatTag, { cache, readField }) => {
          const userId: number | undefined = readField('user_id', deleteChatTag)
          const tagId: number | undefined = readField('tag_id', deleteChatTag)

          removeTagFromChat(userId, tagId, cache)
          removeChatFromFolder(userId, tagId, cache)
        }
      },
    }
  },
  users: {
    keyFields: ["user_id"],
    merge: (existing, incoming, { mergeObjects }) => {
      // console.log('merging users object', existing, incoming)
      return mergeObjects(existing, incoming);
    },
    fields: {
      id: {
        read: (_, { readField }) => readField("user_id"),
      },
      title: {
        read: (_, { readField }) => {
          const fn = readField("first_name");
          const ln = readField("last_name");
          const un = readField("username");
          if (fn) {
            if (ln) return ln + " " + fn;
            return fn;
          }
          return un;
        },
      },
      type: {
        read: () => CHAT_TYPE,
      },
      unreadCount: {
        read: (_, { readField }) => {
          const inCount = readField("read_inbox_max_id") as number;
          const outCount = readField("read_outbox_max_id") as number;
          return inCount - outCount;
        },
      },
      hasUnreadMark: {
        read: (_, { readField }) => {
          const unread = readField("unread");
          return unread !== null ? (unread as boolean) : false;
        },
      },
      last_message: {
        merge: (existing, incoming, { mergeObjects }) => {
          // console.log('merging user object last_message', existing, incoming)
          return mergeObjects(existing, incoming);
        },
      },
      tags: {
        merge: (_, incoming) => incoming,
      },
    },
  },
  messages: {
    keyFields: ['message_id'],
    merge: true,
    fields: {
      isOutgoing: {
        read: (_, { readField }) => {
          return !!readField("staff_id");
        },
      },
      isEdited: {
        read: (_, { readField }) => {
          return !!readField("staff_editor_id");
        },
      },
      date: {
        read: (_, { readField }) => {
          const content = readField("mess") as Message;
          return content.date;
        },
      },
      id: {
        read: (_, { readField }) => {
          // console.log('MESSAGE ENTERS CACHE', readField('message_id'))
          return readField("message_id");
        },
      },
      chatId: {
        read: (_, { readField }) => {
          return readField("user_id");
        },
      },
      content: {
        read: (_, { readField }) => {
          const message = readField('mess') as Message
          const content: ApiMessage['content'] = {}

          if (message.text) {
            content.text = {
              text: message.text || "",
              entities: message.entities,
            };
          }

          if (message.caption) {
            content.text = {
              text: message.caption || "",
              entities: message.caption_entities,
            };
          }

          if (message.sticker) {
            const st = message.sticker;
            content.sticker = {
              id: st.file_id,
              stickerSetId: st.set_name!,
              emoji: st.emoji!,
              isLottie: st.is_animated,
              //@ts-ignore TODO: update telegram-typings
              isVideo: st.is_video,
              stickerSetAccessHash: "",
              width: st.width,
              height: st.height,
            };
          }


          if (message.photo) {
            const ph = message.photo
            content.photo = {
              id: ph[ph.length - 1].file_id,
              sizes: ph.map((p, i) => ({
                ...p,
                type: photoSizes[i]
              })),
            }
          }

          if (message.video) {
            const v = message.video;
            content.video = {
              id: v.file_id,
              width: v.width,
              height: v.height,
              duration: v.duration,
              mimeType: v.mime_type!,
              fileName: v.file_name!,
              size: v.file_size!,
            };
          }

          if (message.voice) {
            const v = message.voice
            content.voice = {
              id: v.file_id,
              duration: v.duration
            }
          }

          if (message.location) {
            const l = message.location;
            content.location = {
              type: "geo",
              geo: {
                lat: l.latitude,
                long: l.longitude,
                accessHash: "",
              },
            };
          }

          if (message.document) {
            content.document = {
              id: message.document.file_id,
              fileName: message.document.file_name || "",
              size: message.document.file_size || -1,
              mimeType: message.document.mime_type || "",
            };
          }

          // TODO: implement other content types if missing
          return content

        }
      },
      forwardInfo: {
        read: (_, { readField }) => {
          const message = readField("mess") as Message;
          const {
            forward_from_chat,
            forward_from,
            forward_sender_name,
            forward_from_message_id,
            forward_signature,
          } = message;

          const hasForward =
            !!forward_from_chat || !!forward_from || !!forward_sender_name;
          let forwardInfo: ApiMessage["forwardInfo"] = {
            isChannelPost: false,
          };

          if (forward_sender_name) {
            forwardInfo = {
              ...forwardInfo,
              isChannelPost: false,
              hiddenUserName: forward_sender_name,
            };
          }

          if (forward_from) {
            const senderUser: ApiUser = {
              firstName: forward_from?.first_name,
              username: forward_from?.username!,
              lastName: forward_from?.last_name,
              id: forward_from?.id!,
              isMin: false,
              type: "senderTypeDefault",
              phoneNumber: "",
            };

            forwardInfo = {
              ...forwardInfo,
              isChannelPost: false,
              senderUserId: forward_from?.id,
              senderUser,
              hiddenUserName: forward_from?.first_name,
            };
          }

          if (forward_from_chat) {
            forwardInfo = {
              ...forwardInfo,
              isChannelPost: true,
              adminTitle: forward_from_chat.title,
              fromMessageId: forward_from_message_id,
              fromChatId: forward_from_chat.id,
              hiddenUserName: forward_from_chat.title,
            };
          }

          return hasForward ? forwardInfo : null;
        },
      },
    },
  }
}

export const cache = new InMemoryCache({
  typePolicies,
});
