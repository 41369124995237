import React, {
  FC, useCallback, useEffect, useRef, useState
} from 'react';

import useLang, { LangFn } from '../../../hooks/useLang';

import { GlobalActions, MessageListType } from '../../../global/types';
import { isMutedVar, draftVar, currentChatIdVar } from '../../../cache';
import { useReactiveVar } from '@apollo/client';
import {
  ApiChat, ApiUser, ApiMessage, ApiMessageOutgoingStatus, ApiFormattedText, MAIN_THREAD_ID,
} from '../../../api/types';

import { ANIMATION_END_DELAY } from '../../../config';
import { IS_SINGLE_COLUMN_LAYOUT } from '../../../util/environment';
import {
  getChatTitle,
  isUserId,
  isActionMessage,
  getPrivateChatUserId,
  getMessageAction,
  getMessageSenderName,
  isChatChannel,
  getMessageMediaHash,
  getMessageSummaryText,
  getMessageMediaThumbDataUri,
  getMessageVideo,
  getMessageSticker,
  selectIsChatMuted,
  getMessageRoundVideo,
} from '../../../global/helpers';
// import {
//   selectChat, selectUser, selectChatMessage, selectOutgoingStatus, selectDraft, selectCurrentMessageList,
//   selectNotifySettings, selectNotifyExceptions,
// } from '../../../modules/selectors';
import { renderActionMessageText } from '../../common/helpers/renderActionMessageText';
import renderText from '../../common/helpers/renderText';
import { fastRaf } from '../../../util/schedulers';
import buildClassName from '../../../util/buildClassName';
import { pick } from '../../../util/iteratees';
import useEnsureMessage from '../../../hooks/useEnsureMessage';
import useChatContextActions from '../../../hooks/useChatContextActions';
import useFlag from '../../../hooks/useFlag';
import useMedia from '../../../hooks/useMedia';
import { ChatAnimationTypes } from './hooks';

import Avatar from '../../common/Avatar';
import VerifiedIcon from '../../common/VerifiedIcon';
import TypingStatus from '../../common/TypingStatus';
import LastMessageMeta from '../../common/LastMessageMeta';
// import DeleteChatModal from '../../common/DeleteChatModal';
import ListItem from '../../ui/ListItem';
import Badge from './Badge';

import './Chat.scss';

type OwnProps = {
  style?: string;
  chatId: number;
  folderId?: number;
  chat: ApiChat;
  // orderDiff: number;
  // animationType: ChatAnimationTypes;
  isSelected: boolean;
  isPinned?: boolean;
};

// type StateProps = {
//   chat?: ApiChat;
//   isMuted?: boolean;
//   privateChatUser?: ApiUser;
//   actionTargetUser?: ApiUser;
//   actionTargetMessage?: ApiMessage;
//   actionTargetChatId?: number;
//   lastMessageSender?: ApiUser;
//   lastMessageOutgoingStatus?: ApiMessageOutgoingStatus;
//   draft?: ApiFormattedText;
//   messageListType?: MessageListType;
//   animationLevel?: number;
//   lastSyncTime?: number;
// };

// type DispatchProps = Pick<GlobalActions, 'openChat' | 'focusLastMessage'>;

const ANIMATION_DURATION = 200;

const focusLastMessage = () => void console.log('FOCUS LAST MESSAGE')

const Chat: FC<OwnProps> = ({
  style,
  chatId,
  folderId,
  chat,
  // orderDiff,
  // animationType,
  isSelected,
  isPinned,
  // chat,
  // isMuted,
  // privateChatUser,
  // actionTargetUser,
  // lastMessageSender,
  // lastMessageOutgoingStatus,
  // actionTargetMessage,
  // actionTargetChatId,
  // draft,
  // messageListType,
  // animationLevel,
  // lastSyncTime,
  // openChat,
  // focusLastMessage,
}) => {
  //console.log('rendering chat ', chatId)
  const ref = useRef<HTMLDivElement>(null);
  const messageListType = 'thread'
  const lastSyncTime = 0

  const isMuted = useReactiveVar(isMutedVar)
  const draft = useReactiveVar(draftVar)

  const privateChatUser = undefined

  const actionTargetUsers = undefined
  const actionTargetMessage = undefined
  const actionTargetChatId = undefined
  const lastMessageSender = undefined

  // const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useFlag();
  // const [shouldRenderDeleteModal, markRenderDeleteModal, unmarkRenderDeleteModal] = useFlag();

  const { lastMessage, typingStatus } = chat || {};
  const isAction = lastMessage && isActionMessage(lastMessage);

  // useEnsureMessage(chatId, isAction ? lastMessage!.replyToMessageId : undefined, actionTargetMessage);
  const [mediaThumbnail, setMediaThumbnail] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (lastMessage && !getMessageSticker(lastMessage)) {
      getMessageMediaThumbDataUri(lastMessage).then(setMediaThumbnail)
    }
  }, [lastMessage])
  const isRoundVideo = Boolean(lastMessage && getMessageRoundVideo(lastMessage));

  // Sets animation excess values when `orderDiff` changes and then resets excess values to animate.
  // useLayoutEffect(() => {
  //   const element = ref.current;

  //   if (animationLevel === 0 || !element) {
  //     return;
  //   }

  //   // TODO Refactor animation: create `useListAnimation` that owns `orderDiff` and `animationType`
  //   if (animationType === ChatAnimationTypes.Opacity) {
  //     element.style.opacity = '0';

  //     fastRaf(() => {
  //       element.classList.add('animate-opacity');
  //       element.style.opacity = '1';
  //     });
  //   } else if (animationType === ChatAnimationTypes.Move) {
  //     element.style.transform = `translate3d(0, ${-orderDiff * 100}%, 0)`;

  //     fastRaf(() => {
  //       element.classList.add('animate-transform');
  //       element.style.transform = '';
  //     });
  //   } else {
  //     return;
  //   }

  //   setTimeout(() => {
  //     fastRaf(() => {
  //       element.classList.remove('animate-opacity', 'animate-transform');
  //       element.style.opacity = '';
  //       element.style.transform = '';
  //     });
  //   }, ANIMATION_DURATION + ANIMATION_END_DELAY);
  // }, [animationLevel, orderDiff, animationType]);

  const handleClick = useCallback(() => {
    currentChatIdVar(chatId)

    if (isSelected && messageListType === 'thread') {
      focusLastMessage();
    }
  }, [
    isSelected,
    messageListType,
    chatId,
    currentChatIdVar,
  ]);

  // function handleDelete() {
  //   markRenderDeleteModal();
  //   openDeleteModal();
  // }

  // const contextActions = useChatContextActions({
  //   chat,
  //   privateChatUser,
  //   handleDelete: () => { },
  //   folderId,
  //   isPinned,
  //   isMuted,
  // });

  const lang = useLang();

  if (!chat) {
    return null;
  }

  function renderLastMessageOrTyping() {
    if (typingStatus && lastMessage && typingStatus.timestamp > lastMessage.date * 1000) {
      return <TypingStatus typingStatus={typingStatus} />;
    }

    if (draft && draft.text.length) {
      return (
        <p className="last-message" dir={lang.isRtl ? 'auto' : 'ltr'}>
          <span className="draft">{lang('Draft')}</span>
          {renderText(draft.text)}
        </p>
      );
    }

    if (!lastMessage) {
      return null;
    }

    if (isAction) {
      const isChat = chat && (isChatChannel(chat) || lastMessage.senderId === lastMessage.chatId);

      return (
        <p className="last-message" dir={lang.isRtl ? 'auto' : 'ltr'}>
          {renderActionMessageText(
            lang,
            lastMessage,
            !isChat ? lastMessageSender : undefined,
            isChat ? chat : undefined,
            actionTargetUsers,
            actionTargetMessage,
            actionTargetChatId,
            { asTextWithSpoilers: true },
          )}
        </p>
      );
    }

    const senderName = getMessageSenderName(lang, chatId, lastMessageSender);

    return (
      <p className="last-message" dir={lang.isRtl ? 'auto' : 'ltr'}>
        {senderName && (
          <>
            <span className="sender-name">{renderText(senderName)}</span>
            <span className="colon">:</span>
          </>
        )}
        {renderMessageSummary(lang, lastMessage!, mediaThumbnail, isRoundVideo)}
      </p>
    );
  }

  const className = buildClassName(
    'Chat chat-item-clickable',
    isUserId(chatId) ? 'private' : 'group',
    isSelected && !IS_SINGLE_COLUMN_LAYOUT && 'selected',
  );

  return (
    <ListItem
      // ref={ref}
      className={className}
      style={style}
      // ripple={!IS_SINGLE_COLUMN_LAYOUT}
      // contextActions={contextActions}
      onClick={handleClick}
    >
      <div className="status">
        <Avatar
          chat={chat}
          user={privateChatUser}
          // withOnlineStatus
          isSavedMessages={false} //{privateChatUser && privateChatUser.isSelf}
        // lastSyncTime={lastSyncTime}
        />
      </div>
      <div className="info">
        <div className="title">
          <h3>{renderText(getChatTitle(lang, chat, privateChatUser))}</h3>
          {chat.isVerified && <VerifiedIcon />}
          {isMuted && <i className="icon-muted-chat" />}
          {chat.lastMessage && (
            <LastMessageMeta message={chat.lastMessage} outgoingStatus={undefined} />//{lastMessageOutgoingStatus} />
          )}
        </div>
        <div className="subtitle">
          {renderLastMessageOrTyping()}
          <Badge chat={chat} isPinned={isPinned} isMuted={isMuted} />
        </div>
      </div>
      {/* {shouldRenderDeleteModal && (
        <DeleteChatModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onCloseAnimationEnd={unmarkRenderDeleteModal}
          chat={chat}
        />
      )} */}
    </ListItem>
  );
};

function renderMessageSummary(lang: LangFn, message: ApiMessage, blobUrl?: string, isRoundVideo?: boolean) {
  if (!blobUrl) {
    return renderText(getMessageSummaryText(lang, message));
  }

  return (
    <span className="media-preview">
      <img src={blobUrl} alt="" className={isRoundVideo ? 'round' : undefined} />
      {getMessageVideo(message) && <i className="icon-play" />}
      {renderText(getMessageSummaryText(lang, message, true))}
    </span>
  );
}

export default Chat

// export default memo(withGlobal<OwnProps>(
//   (global, { chatId }): StateProps => {
//     const chat = selectChat(global, chatId);
//     if (!chat || !chat.lastMessage) {
//       return {};
//     }

//     const { senderId, replyToMessageId, isOutgoing } = chat.lastMessage;
//     const lastMessageSender = senderId ? selectUser(global, senderId) : undefined;
//     const lastMessageAction = getMessageAction(chat.lastMessage);
//     const actionTargetMessage = lastMessageAction && replyToMessageId
//       ? selectChatMessage(global, chat.id, replyToMessageId)
//       : undefined;
//     const { targetUserId: actionTargetUserId, targetChatId: actionTargetChatId } = lastMessageAction || {};
//     const privateChatUserId = getPrivateChatUserId(chat);
//     const { type: messageListType } = selectCurrentMessageList(global) || {};

//     return {
//       chat,
//       isMuted: selectIsChatMuted(chat, selectNotifySettings(global), selectNotifyExceptions(global)),
//       lastMessageSender,
//       ...(isOutgoing && { lastMessageOutgoingStatus: selectOutgoingStatus(global, chat.lastMessage) }),
//       ...(privateChatUserId && { privateChatUser: selectUser(global, privateChatUserId) }),
//       ...(actionTargetUserId && { actionTargetUser: selectUser(global, actionTargetUserId) }),
//       actionTargetChatId,
//       actionTargetMessage,
//       draft: selectDraft(global, chatId, MAIN_THREAD_ID),
//       messageListType,
//       animationLevel: global.settings.byKey.animationLevel,
//       lastSyncTime: global.lastSyncTime,
//     };
//   },
//   (setGlobal, actions): DispatchProps => pick(actions, [
//     'openChat',
//     'focusLastMessage',
//   ]),
// )(Chat));
