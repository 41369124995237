import { MediaViewerOrigin } from '../../../../types';
import type { ApiChat, ApiMessage, ApiUser } from '../../../../api/types';
import { useCallback } from "react";
import { isUserId } from "../../../../global/helpers";
import { currentChatIdVar, mediaViewerVar } from "../../../../cache";
import { useReactiveVar } from "@apollo/client";
const openUserInfo = (obj: any) => void console.log('open user info')
const openMediaViewer = (obj: any) => void console.log('open media viewer')
const openAudioPlayer = (obj: any) => void console.log('open audio player')

const openChat = (obj: any) => void console.log('open chat')
const cancelSendingMessage = (obj: any) => void console.log('cancel sending message')
const markMessagesRead = (obj: any) => void console.log('mark messages read')
export default function useInnerHandlers(
    message: ApiMessage,
    chatId: number,
    threadId: number,
    isScheduled?: boolean,
    avatarPeer?: ApiUser | ApiChat,
    senderPeer?: ApiUser | ApiChat,
) {
    const mediaViewerState = useReactiveVar(mediaViewerVar)

    const { id: messageId } = message;

    const handleAvatarClick = useCallback(() => {
        if (!avatarPeer) {
            return;
        }

        if (isUserId(avatarPeer.id)) {
            openUserInfo({ id: avatarPeer.id });
        } else {
            currentChatIdVar(avatarPeer.id);
        }
    }, [avatarPeer]);


    const handleMediaClick = useCallback((): void => {
        mediaViewerVar({
            ...mediaViewerState,
            chatId,
            threadId,
            messageId,
            origin: isScheduled ? MediaViewerOrigin.ScheduledInline : MediaViewerOrigin.Inline,
        });
    }, [mediaViewerState, chatId, threadId, messageId, isScheduled]);

    const handleAudioPlay = useCallback((): void => {
        openAudioPlayer({ chatId, messageId });
    }, [chatId, messageId]);

    const handleAlbumMediaClick = useCallback((albumMessageId: number): void => {
        openMediaViewer({
            chatId,
            threadId,
            messageId: albumMessageId,
            origin: isScheduled ? MediaViewerOrigin.ScheduledAlbum : MediaViewerOrigin.Album,
        });
    }, [chatId, threadId, isScheduled]);

    const handleReadMedia = useCallback((): void => {
        markMessagesRead({ messageIds: [messageId] });
    }, [messageId]);

    const handleCancelUpload = useCallback(() => {
        cancelSendingMessage({ chatId, messageId });
    }, [chatId, messageId]);

    const handleSenderClick = useCallback(() => {
        if (!senderPeer) {
            return;
        }

        if (isUserId(senderPeer.id)) {
            openUserInfo({ id: senderPeer.id });
        } else {
            currentChatIdVar(senderPeer.id);
        }
    }, [senderPeer]);

    return {
        handleAvatarClick,
        handleMediaClick,
        handleAudioPlay,
        handleAlbumMediaClick,
        handleReadMedia,
        handleCancelUpload,
        handleSenderClick
    };
}
