import React, { FC, useCallback } from 'react';
// import { withGlobal } from '../../lib/teact/teactn';

import { GlobalActions } from '../../global/types';

import { pick } from '../../util/iteratees';
import buildClassName from '../../util/buildClassName';

import Link from '../ui/Link';
import { currentChatIdVar } from '../../cache';

type OwnProps = {
  className?: string;
  chatId?: number;
  children: any;
};


const ChatLink: FC<OwnProps> = ({
  className, chatId, children,
}) => {
  const handleClick = useCallback(() => {
    if (chatId) {
      currentChatIdVar(chatId);
    }
  }, [chatId]);

  if (!chatId) {
    return children;
  }

  return (
    <Link className={buildClassName('ChatLink', className)} onClick={handleClick}>{children}</Link>
  );
};

export default ChatLink
