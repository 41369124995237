import React, {
  FC, useMemo, useCallback, useEffect, useState,
} from 'react';
import { chatFolderVar, currentChatIdVar, currentUserIdVar, onlyMyMessagesVar, onlyUnreadVar } from '../../../cache';
import { useReactiveVar } from '@apollo/client';
import useFilteredChatsQuery from '../../../hooks/useFilteredChatsQuery';

import {
  ApiChat, ApiChatFolder, ApiUser,
} from '../../../api/types';
import { NotifyException, NotifySettings } from '../../../types';

import { ALL_CHATS_PRELOAD_DISABLED, CHAT_HEIGHT_PX, CHAT_LIST_SLICE } from '../../../config';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';

import InfiniteScroll from '../../ui/InfiniteScroll';
import Loading from '../../ui/Loading';
import Chat from './Chat';

type OwnProps = {
  folderType: 'all' | 'archived' | 'folder';
  folderId?: number;
  noChatsText?: string;
  isActive: boolean;
  needRefetch: boolean;
  afterRefetch: () => void;
};

type StateProps = {
  chatsById: Record<number, ApiChat>;
  usersById: Record<number, ApiUser>;
  chatFolder?: ApiChatFolder;
  listIds?: number[];
  currentChatId?: number;
  orderedPinnedIds?: number[];
  lastSyncTime?: number;
  isInDiscussionThread?: boolean;
  notifySettings: NotifySettings;
  notifyExceptions?: Record<number, NotifyException>;
};

enum FolderTypeToListType {
  'all' = 'active',
  'archived' = 'archived'
}

const empty: any[] = []
const loadMoreChats = () => void console.log('LOAD MORE CHATS')
const noop = () => { }

const ChatList: FC<OwnProps> = ({
  folderType,
  folderId,
  noChatsText = 'Chat list is empty.',
  isActive,
  needRefetch,
  afterRefetch
}) => {
  const chatFolder = useReactiveVar(chatFolderVar)
  const currentChatId = useReactiveVar(currentChatIdVar);
  const onlyUnread = useReactiveVar(onlyUnreadVar);
  const onlyMyMessages = useReactiveVar(onlyMyMessagesVar);
  const currentUserId = useReactiveVar(currentUserIdVar);
  const isInDiscussionThread = false
  const [showLoading, setShowLoading] = useState(false)

  const { data: chatsData, fetchMore: fetchMoreChats, refetchWithFilter } = useFilteredChatsQuery({
    tagId: chatFolder && chatFolder?.id > 0 ? chatFolder?.id : undefined,
    unread: onlyUnread? true : undefined,
    operatorsId: onlyMyMessages? currentUserId : undefined
  })

  const [orderById, orderedIds] = useMemo<[ApiChat[], number[]]>(() => {
    const chats = chatsData?.users
    if (!chats) return [[], []]

    const sortedChats = [...chats].sort((a, b) => b.last_message_id! - a.last_message_id!)
    return [sortedChats, sortedChats.map(u => u.user_id as number)]
  }, [chatsData]);

  const getMore = useCallback(() => {
    let offsetMessageId: number | undefined = undefined
    if (orderById?.length) {
      offsetMessageId = orderById[orderById.length - 1].lastMessage?.id!
    }
    fetchMoreChats(offsetMessageId)
  }, [orderById, fetchMoreChats])

  // // TODO: load more chats from the API here:
  const [viewportIds, _getMore] = useInfiniteScroll(
    noop,
    orderedIds,
    undefined,
    CHAT_LIST_SLICE,
    folderType === 'all' && !ALL_CHATS_PRELOAD_DISABLED,
  );

  function renderChats() {
    const viewportOffset = orderedIds!.indexOf(viewportIds![0]);
    const pinnedOffset = viewportOffset // + chatArrays!.pinnedChats.length;

    return (
      <div className="scroll-container">
        {(showLoading) && <Loading key="loading"/>}
        {! showLoading && orderById.map((chat, i) => {
          return (
            <Chat
              key={chat.id}
              chatId={chat.id}
              folderId={folderId}
              chat={chat}
              isSelected={chat.id === currentChatId && !isInDiscussionThread}
            />
          );
        })}
      </div>
    );
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isActive && orderedIds) {
        if (e.ctrlKey && e.code.startsWith('Digit')) {
          const [, digit] = e.code.match(/Digit(\d)/) || [];
          if (!digit) return;

          const position = Number(digit) - 1;
          if (position > orderedIds.length - 1) return;

          currentChatIdVar(orderedIds[position]);
        }

        if (e.altKey) {
          const targetIndexDelta = e.key === 'ArrowDown' ? 1 : e.key === 'ArrowUp' ? -1 : undefined;
          if (!targetIndexDelta) return;

          if (!currentChatId) {
            e.preventDefault();
            currentChatIdVar(orderedIds[0])
            return;
          }

          const position = orderedIds.indexOf(currentChatId);

          if (position === -1) {
            return;
          }
          const nextId = orderedIds[position + targetIndexDelta];

          e.preventDefault();
          currentChatIdVar(nextId)
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  });

  useEffect(() => {
    if (!needRefetch) return
    setShowLoading(true)
    refetchWithFilter().catch((e) => 
      console.error(e)
    ).finally(() => {
      afterRefetch()
      setShowLoading(false)
    })
  },[needRefetch])

  return (
    !showLoading ?
      <InfiniteScroll
        className="chat-list custom-scroll"
        items={viewportIds}
        onLoadMore={getMore}
        preloadBackwards={0}
        noFastList
        noScrollRestore
      >
        {viewportIds && (viewportIds.length ? 
          (
            renderChats()
          ) : (
            <div className="no-results">{noChatsText}</div>
          )
        )}
      </InfiniteScroll>
        : 
      <div className="chat-list__loader">
        <Loading key="loading"/>
      </div>
  );
};

export default ChatList
