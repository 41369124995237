import { useEffect } from 'react'
import { currentChatIdVar } from '../cache'

interface KorostelChatEvent {
  id?: number | undefined
}

const useKorostelEvents = () => {
  useEffect(() => {
    const handleEvent = (event: MessageEvent<KorostelChatEvent>) => {
      const id = event?.data?.id
      if (typeof id !== 'number') return

      currentChatIdVar(id)
    }
    window.addEventListener('message', handleEvent)
    return () => window.removeEventListener('message', handleEvent)
  }, [])
}

export default useKorostelEvents