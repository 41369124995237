declare global {
    interface Window {
      env: any
    }
  }

  type EnvType = {
    REACT_APP_DOMAIN: string,
    REACT_APP_AUTH_BOT_NAME: string,
    BACKEND_API_URL: string,
  }

  export const env: EnvType = { ...process.env, ...window.env }