import React, { FC, useCallback } from 'react';
// import { withGlobal } from '../../lib/teact/teactn';

import { GlobalActions } from '../../global/types';
import { ApiChat, ApiUser } from '../../api/types';

import { pick } from '../../util/iteratees';
import buildClassName from '../../util/buildClassName';

import Link from '../ui/Link';

type OwnProps = {
  className?: string;
  sender?: ApiUser | ApiChat;
  children: any;
};

const openUserInfo = () => {
  void console.log('OPEN USER INFO')
}

const UserLink: FC<OwnProps> = ({
  className, sender, children,
}) => {
  const handleClick = useCallback(() => {
    if (sender) {
      // openUserInfo({ id: sender.id });
      openUserInfo();
    }
  }, [sender, openUserInfo]);

  if (!sender) {
    return children;
  }

  return (
    <Link className={buildClassName('UserLink', className)} onClick={handleClick}>{children}</Link>
  );
};

export default UserLink
