import React, { FC, useRef } from 'react';

import { ApiUser, ApiMessage, ApiChat } from '../../api/types';

import {
  getMessageMediaHash,
  isActionMessage,
  getSenderTitle,
} from '../../global/helpers';
import renderText from './helpers/renderText';
import { getPictogramDimensions } from './helpers/mediaDimensions';
import buildClassName from '../../util/buildClassName';
import { ObserveFn, useIsIntersecting } from '../../hooks/useIntersectionObserver';
import useMedia from '../../hooks/useMedia';
//import useWebpThumbnail from '../../hooks/useWebpThumbnail';
import useLang from '../../hooks/useLang';

import ActionMessage from '../middle/ActionMessage';

import './EmbeddedMessage.scss';

type OwnProps = {
  observeIntersection?: ObserveFn;
  className?: string;
  message?: ApiMessage;
  sender?: ApiUser | ApiChat;
  title?: string;
  customText?: string;
  onClick: NoneToVoidFunction;
};

const NBSP = '\u00A0';

const EmbeddedMessage: FC<OwnProps> = ({
  className,
  message,
  sender,
  title,
  customText,
  observeIntersection,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsIntersecting(ref, observeIntersection);

  const mediaBlobUrl = useMedia(message && getMessageMediaHash(message, 'pictogram'), !isIntersecting);
  const pictogramId = message && `sticker-reply-thumb${message.id}`;
  //const mediaThumbnail = useWebpThumbnail(message);

  const lang = useLang();

  const senderTitle = sender && getSenderTitle(lang, sender);

  return (
    <div
      ref={ref}
      className={buildClassName('EmbeddedMessage', className)}
      onClick={message ? onClick : undefined}
    >
      {/*mediaThumbnail && renderPictogram(pictogramId, mediaThumbnail, mediaBlobUrl)*/}
      <div className="message-text">
        <div className="message-title">{renderText(senderTitle || title || NBSP)}</div>
        <p>
          {!message ? (
            customText || NBSP
          ) : <ActionMessage message={message} isEmbedded />}
          {/*!message ? (
            customText || NBSP
          ) : isActionMessage(message) ? (
            <ActionMessage message={message} isEmbedded />
          ) : (
            renderText(getMessageSummaryText(lang, message, Boolean(mediaThumbnail)))
          )*/}
        </p>
      </div>
    </div>
  );
};

function renderPictogram(
  id: string | undefined,
  thumbDataUri: string,
  blobUrl?: string,
) {
  const { width, height } = getPictogramDimensions();

  return (
    <img id={id} src={blobUrl || thumbDataUri} width={width} height={height} alt="" />
  );
}

export default EmbeddedMessage;
