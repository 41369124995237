import React, { FC } from 'react';

import './VerifiedIcon.scss';

const VerifiedIcon: FC = () => {
  return (
    <span className="VerifiedIcon" />
  );
};

export default VerifiedIcon;
