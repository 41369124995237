import React, { FC, useState, useRef } from 'react';

import Menu from './Menu';

import './DropdownMenu.scss';
import Button from '../ui/Button';
import { IS_SINGLE_COLUMN_LAYOUT } from '../../util/environment';
import buildClassName from '../../util/buildClassName';
import useLang from '../../hooks/useLang';

type OwnProps = {
  className?: string;
  positionX?: 'left' | 'right';
  positionY?: 'top' | 'bottom';
  hasMenu: boolean;
  footer?: string;
  children: any;
  trigger?: FC<{ onTrigger: () => void; isOpen?: boolean }>;
};

const MainButton: FC<{ onTrigger: () => void; isOpen?: boolean, hasMenu: boolean }> = ({ onTrigger, isOpen, hasMenu }) => {
  const lang = useLang()
  return (
    <Button
      round
      ripple={hasMenu && !IS_SINGLE_COLUMN_LAYOUT}
      size="smaller"
      color="translucent"
      className={isOpen ? 'active' : ''}
      onClick={onTrigger}
      ariaLabel={hasMenu ? lang('AccDescrOpenMenu2') : 'Return to chat list'}
    >
      <div className={buildClassName('animated-menu-icon', !hasMenu && 'state-back')} />
    </Button>
  );
};

const DropdownMenu: FC<OwnProps> = ({
  trigger,
  className,
  children,
  positionX = 'left',
  positionY = 'top',
  footer,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<any>) => {
    const menu = menuRef.current;

    if (!isOpen || e.keyCode !== 40 || !menu) {
      return;
    }

    const focusedElement = document.activeElement;
    const elementChildren = Array.from(menu.children);

    if (!focusedElement || elementChildren.indexOf(focusedElement) === -1) {
      (elementChildren[0] as HTMLElement).focus();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`DropdownMenu ${className || ''}`}
      onKeyDown={handleKeyDown}
    >
        {trigger ? trigger({ onTrigger: () => setOpen(!isOpen) })
            : <MainButton hasMenu={true} isOpen={isOpen} onTrigger={() => setOpen(o => !o)}/>
        }
      {isOpen && <Menu
        ref={menuRef}
        containerRef={dropdownRef}
        isOpen={isOpen}
        className={className || ''}
        positionX={positionX}
        positionY={positionY}
        footer={footer}
        autoClose
        onClose={handleClose}
      >
        {children}
      </Menu>}
    </div>
  );
};

export default DropdownMenu;
