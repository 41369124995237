import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { AUTH_STORAGE_KEY, AUTH_REFRESH_STORAGE_KEY } from '../config'
import { authTokenVar } from '../cache'

const useLogout = () => {
    const client = useApolloClient()

    return useCallback(() => {
        localStorage.removeItem(AUTH_STORAGE_KEY)
        localStorage.removeItem(AUTH_REFRESH_STORAGE_KEY)
        client.clearStore()
        authTokenVar(undefined)
    }, [client])
}

export default useLogout