import React, { FC, useEffect } from 'react';
import { updateSizes } from './util/windowSize';
import UiLoader from './components/common/UiLoader';
import Main from './components/main/Main';
import { useReactiveVar } from '@apollo/client';
import { authTokenVar } from './cache'
import Auth from './components/auth/Auth'
import useAppLayout from './hooks/useAppLayout';
// import { hasStoredSession } from './util/sessions';
// import Test from './components/test/TestNoRedundancy';

const App: FC = () => {
    //const [isInactive, markInactive] = useFlag(false);
    const { isMobile } = useAppLayout();
    useEffect(() => {
        updateSizes();
    }, []);

    const token = useReactiveVar(authTokenVar)

    if (!token) {
        return (
            <Auth />
        )
    }

    return (
        <UiLoader page="main" key="main">
            {/* <AppInactive /> */}
            {<Main  isMobile={isMobile} />}
            <div id='portals' />
        </UiLoader>
    )

    // if (authState) {
    //     switch (authState) {
    //         case 'authorizationStateWaitPhoneNumber':
    //         case 'authorizationStateWaitCode':
    //         case 'authorizationStateWaitPassword':
    //         case 'authorizationStateWaitRegistration':
    //         case 'authorizationStateWaitQrCode':
    //             return <Auth />;
    //         case 'authorizationStateClosed':
    //         case 'authorizationStateClosing':
    //         case 'authorizationStateLoggingOut':
    //         case 'authorizationStateReady':
    //             return renderMain();
    //     }
    // }

    // return hasStoredSession(true) ? renderMain() : <Auth />;
};

// function renderMain() {
//     return (
//         <UiLoader page="main" key="main">
//             <Main />
//         </UiLoader>
//     );
// }

export default App
