import { useMessagesQuery, MessagesQueryVariables, Order_By } from '../graphql/schema'
import { useCallback } from 'react'

interface ChatFilters {
  offsetMessageId?: number
  chatId: number,
  direction?: 'forward' | 'backward'
}

const generateFilterVariables = ({ offsetMessageId, chatId, direction }: ChatFilters): MessagesQueryVariables => {
  const variables: MessagesQueryVariables = {
    limit: 30,
  }

  const where: MessagesQueryVariables['where'] = {
    user_id: {
      _eq: chatId
    }
  }

  // pagination
  where.message_id = {
    _is_null: false
  }
  if (offsetMessageId) {
    if (direction === 'forward')
      where.message_id._gte = offsetMessageId
    else 
      where.message_id._lte = offsetMessageId;
  }

  const order_by: MessagesQueryVariables['order_by'] = {
    message_id: direction === 'forward' ? Order_By.Asc : Order_By.Desc
  }

  variables.where = where
  variables.order_by = order_by
  return variables
}

const useFilteredMessagesQuery = (filters: ChatFilters) => {

  const result = useMessagesQuery({
    variables: generateFilterVariables(filters)
  })

  const fetchMore = result.fetchMore

  const filteredFetchMore = useCallback((nextOffsetMessageId?: number, direction: 'backward' | 'forward' = 'backward') => {
    console.log('fetching more', nextOffsetMessageId)
    return fetchMore({
      variables: generateFilterVariables({
        chatId: filters.chatId,
        offsetMessageId: nextOffsetMessageId,
        direction
      })
    })
  }, [filters.chatId, fetchMore])

  return {
    ...result,
    fetchMore: filteredFetchMore
  }
}

export default useFilteredMessagesQuery