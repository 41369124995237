import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { currentChatIdVar } from '../cache';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const reactiveChatId = useReactiveVar(currentChatIdVar);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsChatId = Number(searchParams.get('chat'));

  
  useEffect(() => {
    if (isNaN(searchParamsChatId)) {
      searchParams.delete('chat');
      setSearchParams(searchParams);
      return;
    }
    
    if (reactiveChatId) {
      if (searchParamsChatId !== reactiveChatId) {
        setSearchParams({ 'chat': `${reactiveChatId}` });
      }
    } else {
      currentChatIdVar(searchParamsChatId);
    }
  }, [reactiveChatId, searchParamsChatId, searchParams, setSearchParams]);
}
