import React, {
  FC, memo, useCallback, useEffect, useRef, useState,
} from 'react';
import DoneAll from '@mui/icons-material/DoneAll';
import RemoveDone from '@mui/icons-material/RemoveDone';
import { useReactiveVar } from '@apollo/client';
import { MessageListType } from '../../../global/types';
import { QuickTemplatePickerMenu } from '../../columnMiddle/QuickTemplatePickerMenu'
import InsertQuickTemplateIcon from '../../columnMiddle/Assignment'
import {
  currentUserIdVar,
  isRightColumnShownVar,
  editingId
} from '../../../cache'
import { ApiAttachment, ApiSticker } from '../../../api/types';
import { EDITABLE_INPUT_ID } from '../../../config';
import {  IS_MOBILE_SCREEN, IS_EMOJI_SUPPORTED } from '../../../util/environment';
import focusEditableElement from '../../../util/focusEditableElement';
import parseMessageInput from './helpers/parseMessageInput';
import buildAttachment from './helpers/buildAttachment';
import renderText from '../../common/helpers/renderText';
import insertHtmlInSelection from '../../../util/insertHtmlInSelection';
import buildClassName from '../../../util/buildClassName';
import { isSelectionInsideInput } from './helpers/selection';
import useFlag from '../../../hooks/useFlag';
import useVoiceRecording from './hooks/useVoiceRecording';
import useClipboardPaste from './hooks/useClipboardPaste';
import useDraft from './hooks/useDraft';
import useEditing from './hooks/useEditing';
import usePrevious from '../../../hooks/usePrevious';
import useEmojiTooltip from './hooks/useEmojiTooltip';
import useMentionTooltip from './hooks/useMentionTooltip';
import useContextMenuHandlers from '../../../hooks/useContextMenuHandlers';
import useSendMessage from '../../../hooks/useSendMessage'
import Button from '../../ui/Button';
import MentionTooltip from './MentionTooltip';
import MessageInput from './MessageInput';
import AttachmentModal from './AttachmentModal';
import DropArea, { DropAreaState } from './DropArea';
import WebPagePreview from './WebPagePreview';
import Portal from '../../ui/Portal';
import './Composer.scss';
import { isAppleDevice } from '../../../util/common';
import { useChatQuery, useMessageByIdQuery, useSendStickerMutation } from '../../../graphql/schema';
import useEditMessage from '../../../hooks/useEditMessage';
import useChangeIsReadState from '../../../hooks/useChangeIsReadState';
import captureEscKeyListener from '../../../util/captureEscKeyListener';
import SymbolMenu from './SymbolMenu';
import parseEmojiOnlyString from '../../common/helpers/parseEmojiOnlyString';

const recentEmojis: string[] = [];
const usersById: any[] = []

type OwnProps = {
  chatId: number;
  threadId: number;
  messageListType: MessageListType;
  dropAreaState: string;
  onDropHide: NoneToVoidFunction;
};

enum MainButtonState {
  Send = 'send',
  Record = 'record',
  Edit = 'edit',
}

const allowedAttachmentOptions: any = {}

const VOICE_RECORDING_FILENAME = 'wonderful-voice-message.ogg';
// When voice recording is active, composer placeholder will hide to prevent overlapping

const SELECT_MODE_TRANSITION_MS = 200;
const CAPTION_MAX_LENGTH = 1024;
const SENDING_ANIMATION_DURATION = 350;
// eslint-disable-next-line max-len

const saveDraft = (obj: any) => void console.log('save draft');
const clearDraft = (obj: any) => void console.log('clear draft');
const showError = (obj: any) => void console.log('show error');
const setStickerSearchQuery = (obj: any) => void console.log('set sticker search query');
const setGifSearchQuery = (obj: any) => void console.log('set gif search query');
const forwardMessages = () => void console.log('forward messages');
const addRecentEmoji = (obj: any) => void console.log('add recent emoji');

const groupChatMembers: any[] = [] //TODO: fix chat members
export const OS_SPECIFIC_META_KEY_NAME = isAppleDevice() ? "⌘" : "ctrl";

const Composer: FC<OwnProps> = ({
  dropAreaState,
  onDropHide,
  chatId,
  threadId,
  messageListType,
}) => {

  const messageId = useReactiveVar(editingId)
  const { data } = useChatQuery({ variables: { id: chatId }, skip: !chatId });
  const chat = data?.user;
  const { data: messagesData, refetch } = useMessageByIdQuery({
    variables: {
      id: messageId
    }
  });
  useEffect(() => {
    refetch({
      id: messageId
    })
  }, [messageId, refetch])

  const editingMessage = messagesData?.messages[0]?.id === editingId()
    ? messagesData?.messages[0] 
    : undefined;
  const isForwarding = false
  const canSuggestMembers = false
  const shouldSchedule = false
  const isSelectModeActive = false
  const draft = undefined
  const currentUserId = useReactiveVar(currentUserIdVar)
  const isRightColumnShown = useReactiveVar(isRightColumnShownVar)
  const [html, setHtml] = useState<string>('');
  const lastMessageSendTimeSeconds = useRef<number>();
  const mobileEmojiButtonRef = useRef<HTMLButtonElement>(null);
  const prevDropAreaState = usePrevious(dropAreaState);
  const [ openCalendar, closeCalendar] = useFlag();
  const sendMessage = useSendMessage()
  const editMessage = useEditMessage()
  const changeIsReadState = useChangeIsReadState()
  const [sendStickerMutation] = useSendStickerMutation()


  // Cache for frequently updated state
  const htmlRef = useRef<string>(html);
  useEffect(() => {
    htmlRef.current = html;
  }, [html]);

  useEffect(() => {
    lastMessageSendTimeSeconds.current = undefined;
  }, [chatId]);



  const [attachments, setAttachments] = useState<ApiAttachment[]>([]);
  const [isSymbolMenuOpen, openSymbolMenu, closeSymbolMenu] = useFlag();
  const [isSuggestionsMenuOpen, openSuggestionsMenu, closeSuggestionsMenu] = useFlag()
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useFlag();
  const [isSymbolMenuLoaded, onSymbolMenuLoadingComplete] = useFlag();
  const [isHoverDisabled, disableHover, enableHover] = useFlag();

  const {
    startRecordingVoice,
    stopRecordingVoice,
    pauseRecordingVoice,
    activeVoiceRecording,
    // currentRecordTime,
    recordButtonRef: mainButtonRef,
    // startRecordTimeRef,
  } = useVoiceRecording();

  const mainButtonState: string = editingMessage
    ? MainButtonState.Edit
    : MainButtonState.Send

  const canShowCustomSendMenu = !shouldSchedule;

  const {
    isMentionTooltipOpen, mentionFilter,
    closeMentionTooltip, insertMention,
    mentionFilteredMembers,
  } = useMentionTooltip(
    canSuggestMembers && !attachments.length,
    html,
    setHtml,
    undefined,
    groupChatMembers,
    currentUserId,
    usersById,
  );

  const {
    handleContextMenu,
  } = useContextMenuHandlers(mainButtonRef, !(mainButtonState === MainButtonState.Send && canShowCustomSendMenu));

  const insertTextAndUpdateCursor = useCallback((text: string, inputId: string = EDITABLE_INPUT_ID) => {
    const selection = window.getSelection()!;
    const messageInput = document.getElementById(inputId)!;
    const newHtml = renderText(text, ['escape_html', 'emoji_html', 'br_html'])
      .join('')
      .replace(/\u200b+/g, '\u200b');
    if (selection.rangeCount) {
      const selectionRange = selection.getRangeAt(0);
      if (isSelectionInsideInput(selectionRange)) {
        if (IS_EMOJI_SUPPORTED) {
          // Insertion will trigger `onChange` in MessageInput, so no need to setHtml in state
          document.execCommand('insertText', false, text);
        } else {
          insertHtmlInSelection(newHtml);
          messageInput.dispatchEvent(new Event('input', { bubbles: true }));
        }
        return;
      }

      setHtml(`${htmlRef.current!}${newHtml}`);

      if (!IS_MOBILE_SCREEN) {
        // If selection is outside of input, set cursor at the end of input
        requestAnimationFrame(() => {
          focusEditableElement(messageInput);
        });
      }
    } else {
      setHtml(`${htmlRef.current!}${newHtml}`);
    }
  }, []);

  const resetComposer = useCallback(() => {
      setHtml('');
      setAttachments([]);
      closeCalendar();
      closeMentionTooltip();

      if (IS_MOBILE_SCREEN) {
        // @perf
        setTimeout(() => closeSymbolMenu(), SENDING_ANIMATION_DURATION);
      } else {
        closeSymbolMenu();
      }
  }, [closeCalendar, closeMentionTooltip, closeSymbolMenu]);

  const handleStickerSelect = useCallback((sticker: ApiSticker) => {
    sticker = {
      ...sticker,
      isPreloadedGlobally: true,
    };

    if (!shouldSchedule) {
      sendMessage({ sticker });
      requestAnimationFrame(resetComposer);
    }
  }, [shouldSchedule, openCalendar, sendMessage, resetComposer]);

  // Handle chat change
  const prevChatId = usePrevious(chatId);
  useEffect(() => {
    if (!prevChatId || chatId === prevChatId) {
      return;
    }
    stopRecordingVoice();
    resetComposer();
  }, [chatId, prevChatId, resetComposer, stopRecordingVoice]);

  useEffect(() => {
    if(!IS_MOBILE_SCREEN) return;
    const button = mobileEmojiButtonRef.current!;

    function closeMenu() {
      closeSymbolMenu();
    }

    if (isSymbolMenuOpen) {
      button.addEventListener('focus', closeMenu);
    }

    return () => {
      button.removeEventListener('focus', closeMenu);
    };
  }, [isSymbolMenuOpen]);

  const handleEditComplete = useEditing(htmlRef, setHtml, editingMessage, resetComposer, openDeleteModal, editMessage, chatId);


  useDraft(draft, chatId, threadId, html, htmlRef, setHtml, editingMessage, saveDraft, clearDraft);
  useClipboardPaste(insertTextAndUpdateCursor, setAttachments, editingMessage);

  const handleFileSelect = useCallback(async (files: File[], isQuick: boolean) => {
    setAttachments(await Promise.all(files.map((file) => buildAttachment(file.name, file, isQuick))));
  }, []);

  const handleAppendFiles = useCallback(async (files: File[], isQuick: boolean) => {
    setAttachments([
      ...attachments,
      ...await Promise.all(files.map((file) => buildAttachment(file.name, file, isQuick))),
    ]);
  }, [attachments]);

  const handleClearAttachment = useCallback(() => {
    setAttachments([]);
  }, []);

  const handleSend = useCallback(async (isSilent = false, scheduledAt?: number) => {

    let currentAttachments = attachments;
    if (activeVoiceRecording) {
      const record = await stopRecordingVoice();
      if (record) {
        const { blob, duration, waveform } = record;
        currentAttachments = [await buildAttachment(
          VOICE_RECORDING_FILENAME,
          blob,
          false,
          { voice: { duration, waveform } },
        )];
      }
    }

    const { text, entities } = parseMessageInput(htmlRef.current!);
    if (!currentAttachments.length && !text && !isForwarding) {
      return;
    }

    if (currentAttachments.length && text && text.length > CAPTION_MAX_LENGTH) {
      const extraLength = text.length - CAPTION_MAX_LENGTH;
      showError({
        error: {
          message: 'CAPTION_TOO_LONG_PLEASE_REMOVE_CHARACTERS',
          textParams: {
            '{EXTRA_CHARS_COUNT}': extraLength,
            '{PLURAL_S}': extraLength > 1 ? 's' : '',
          },
        },
      });
      return;
    }

    /*if(!currentAttachments.length && text.length) {  TODO use if will be creating installl comand
      const needInstallStickers = text.indexOf('/install') === 1;
      needInstallStickers && handleInstallStickers(text.substring(8))
    } */
    if (currentAttachments.length || text) {
      await sendMessage({
        text,
        entities,
        attachments: currentAttachments,
        scheduledAt,
        isSilent,
      }).then((props) => {
        if(!props?.errors) {
          clearDraft({ chatId, localOnly: true });
          requestAnimationFrame(resetComposer);
        }
      });      
    }
    if (isForwarding) {
      forwardMessages();
    }

    lastMessageSendTimeSeconds.current = Math.floor(Date.now() / 1000);    

    // Wait until message animation starts
    if(!(currentAttachments.length || text)) {
      clearDraft({ chatId, localOnly: true });
      requestAnimationFrame(resetComposer);
    }
  }, [
    activeVoiceRecording, attachments, chatId, isForwarding,
    sendMessage, stopRecordingVoice, resetComposer
  ]);

  useEffect(() => {
    if (isRightColumnShown && IS_MOBILE_SCREEN) {
      closeSymbolMenu();
    }
  }, [isRightColumnShown, closeSymbolMenu]);

  useEffect(() => {
    if (isSelectModeActive) {
      disableHover();
    } else {
      setTimeout(() => {
        enableHover();
      }, SELECT_MODE_TRANSITION_MS);
    }
  }, [isSelectModeActive, enableHover, disableHover]);

  useEffect(() => {
    return editingMessage?.message_id
      ? captureEscKeyListener(() => {
        editingId(0)
      })
      : undefined;
  }, [editingMessage?.message_id]);

  const mainButtonHandler = useCallback(() => {
    switch (mainButtonState) {
      case MainButtonState.Send:
          handleSend();
        break;
      case MainButtonState.Record:
        startRecordingVoice();
        break;
      case MainButtonState.Edit:
        handleEditComplete();
        break;
      default:
        break;
    }
  }, [
    mainButtonState, resetComposer, shouldSchedule, startRecordingVoice, handleEditComplete,
    activeVoiceRecording, openCalendar, pauseRecordingVoice, handleSend,
  ]);

  const areVoiceMessagesNotAllowed = mainButtonState === MainButtonState.Record
    && !allowedAttachmentOptions.canAttachMedia;

  const scheduledDefaultDate = new Date();
  scheduledDefaultDate.setSeconds(0);
  scheduledDefaultDate.setMilliseconds(0);

  const scheduledMaxDate = new Date();
  scheduledMaxDate.setFullYear(scheduledMaxDate.getFullYear() + 1);

  let sendButtonAriaLabel = 'Send message';
  switch (mainButtonState) {
    case MainButtonState.Edit:
      sendButtonAriaLabel = 'Save edited message';
      break;
    case MainButtonState.Record:
      sendButtonAriaLabel = areVoiceMessagesNotAllowed
        ? 'Posting media content is not allowed in this group.'
        : 'Record a voice message';
  }

  const className = buildClassName(
    'Composer',
    !isSelectModeActive && 'shown',
    isHoverDisabled && 'hover-disabled',
  );

  return (
    <div className={className}>
      {allowedAttachmentOptions.canAttachMedia && (
        <Portal containerId="#middle-column-portals">
          <DropArea
            isOpen={dropAreaState !== DropAreaState.None}
            withQuick={[dropAreaState, prevDropAreaState].includes(DropAreaState.QuickFile)}
            onHide={onDropHide}
            onFileSelect={handleFileSelect}
          />
        </Portal>
      )}
      {!!attachments.length && <AttachmentModal
        attachments={attachments}
        caption={attachments.length ? html : ''}
        canSuggestMembers={canSuggestMembers}
        groupChatMembers={groupChatMembers}
        currentUserId={currentUserId}
        usersById={usersById}
        recentEmojis={recentEmojis}
        onCaptionUpdate={setHtml}
        addRecentEmoji={addRecentEmoji}
        onSend={handleSend}
        onFileAppend={handleAppendFiles}
        onClear={handleClearAttachment}
      />}
      <SymbolMenu 
        isOpen={isSymbolMenuOpen} 
        allowedAttachmentOptions={{
          canAttachMedia: true,
          canAttachPolls: true,
          canSendStickers: true,
          canSendGifs: true,
          canAttachEmbedLinks: true
        }}
        onClose={closeSymbolMenu} 
        onEmojiSelect={(textEmoji)=>{
          setHtml(`${html}${textEmoji}`);
        }}
        onRemoveSymbol={()=> setHtml(parseEmojiOnlyString(html.slice(html.length-2))
          ? html.slice(0,-2) 
          : html.slice(0,-1)
        )}
        onStickerSelect={(sticker) => chatId && sendStickerMutation({variables: {
          input: {
              chat_id: chatId,
              sticker: sticker.id
          }
      },})}
      />

      <MentionTooltip
        isOpen={isMentionTooltipOpen}
        filter={mentionFilter}
        onClose={closeMentionTooltip}
        onInsertUserName={insertMention}
        filteredChatMembers={mentionFilteredMembers}
        usersById={usersById}
      />
      <div id="message-compose">
        <WebPagePreview
          chatId={chatId}
          threadId={threadId}
          messageText={!attachments.length ? html : ''}
          disabled={!allowedAttachmentOptions.canAttachEmbedLinks}
        />
        <div className="message-input-wrapper">
          <Button
            round
            color="translucent"
            onClick={() => {
              if(isSuggestionsMenuOpen) {
                closeSuggestionsMenu();
              } else {
                openSuggestionsMenu();
                // Здесь надо переключать курсор на поиск по шаблонам
                // Но почему-то, как ни крути, при первом открытии шаблона переключения курсора не происходит...
                // Я пытался делать это внутри самого окна с поиском шаблона в разных хуках - тоже безрезультатно
                // Поэтому сделал это отложенной операцией - так работает \_(o_o)_/
                setTimeout(() => {
                  document.getElementById("template-picker-query-input")?.focus();
                  // @ts-ignore
                  document.getElementById("template-picker-query-input")?.select();
                }, 10)
              }
            }}
            ariaLabel="Choose fast reply"
          >
            <InsertQuickTemplateIcon />
          </Button>
          {IS_MOBILE_SCREEN &&
            <Button
              round
              color="translucent"
              ref={mobileEmojiButtonRef}
              onClick={(e)=> {
                focusEditableElement(e.currentTarget)
                openSymbolMenu();
              }}
              ariaLabel="Choose emoji"
            >
              <i className="icon-smile"/>
            </Button>
          }

          <MessageInput
            id="message-input-text"
            html={!attachments.length ? html : ''}
            placeholder='Message' // TODO: add shortcuts
            shouldSetFocus={IS_MOBILE_SCREEN}
            shouldSupressFocus={IS_MOBILE_SCREEN && isSymbolMenuOpen}
            shouldSupressTextFormatter={isMentionTooltipOpen}
            onUpdate={setHtml}
            onSend={mainButtonState === MainButtonState.Edit
              ? handleEditComplete
              : handleSend}
            onSupressedFocus={closeSymbolMenu}
          />
          <QuickTemplatePickerMenu
            isOpen={isSuggestionsMenuOpen}
            onClose={closeSuggestionsMenu}
            onSelectTemplate={(text) => {
              if (!text) return;
              setHtml(text)
            }}
          />
        </div>
      </div>
      {!IS_MOBILE_SCREEN && <Button 
          round 
          color="secondary" 
          onClick={openSymbolMenu}
        >
          <i className="icon-smile"/>
        </Button>
      }
      {activeVoiceRecording && (
        <Button
          round
          color="danger"
          className="cancel"
          onClick={stopRecordingVoice}
          ariaLabel="Cancel voice recording"
        >
          <i className="icon-delete" />
        </Button>
      )}
      {html.length ? (
        <Button
          ref={mainButtonRef}
          round
          color="secondary"
          className={`${mainButtonState} ${activeVoiceRecording ? "recording" : ""
            }`}
          disabled={areVoiceMessagesNotAllowed}
          onClick={mainButtonHandler}
          onContextMenu={
            mainButtonState === MainButtonState.Send && canShowCustomSendMenu
              ? handleContextMenu
              : undefined
          }
        >
          <i className="icon-send" />
          <i className="icon-microphone-alt" />
          <i className="icon-check" />
        </Button>
      ) : (
        <Button
          round
          color="secondary"
          className={`${mainButtonState}`}
          onClick={() => { changeIsReadState(!chat?.hasUnreadMark) }}
          onContextMenu={
            mainButtonState === MainButtonState.Send && canShowCustomSendMenu
              ? handleContextMenu
              : undefined
          }
        >
          {chat?.hasUnreadMark ? <DoneAll className="icon-send" /> : <RemoveDone className="icon-send" />}
          <i className="icon-microphone-alt" />
          <i className="icon-check" />
        </Button>
      )}
 
    </div>
  );
};

export default memo(Composer)
