import React, { FC, memo, useCallback } from 'react';
// import { getDispatch } from '../../lib/teact/teactn';
import convertPunycode from '../../lib/punycode';

import { DEBUG, RE_TG_LINK, RE_TME_LINK } from '../../config';
import buildClassName from '../../util/buildClassName';
import ConfirmDialog from '../ui/ConfirmDialog';
import useFlag from '../../hooks/useFlag';
import useLang from '../../hooks/useLang';

const URL_LENGTH_LIMIT = 150;

type OwnProps = {
  url?: string;
  text: string;
  className?: string;
  children?: any;
  isRtl?: boolean;
};

const SafeLink: FC<OwnProps> = ({
  url,
  text,
  className,
  children,
  isRtl,
}) => {
  const content = children || text;

  const lang = useLang();

  const [isDialogOpen, openDialog, closeDialog] = useFlag();

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!url) {
      return true;
    }

    if (
      url.match(RE_TME_LINK)
      || url.match(RE_TG_LINK)
      || url === content
      || (content.length === 1 && url === content[0])
    ) {
      // The link is safe
      return true;
    }

    openDialog();
    e.preventDefault();
    return false;
  }, [url, content, openDialog]);

  const handleRedirectToUrl = useCallback(() => {
    window.open(ensureProtocol(url), '_blank', 'noopener');
    closeDialog();
  }, [url]);

  if (!url) {
    return null;
  }

  const classNames = buildClassName(
    className || 'text-entity-link',
    text.length > 50 && 'long-word-break-all',
  );

  return (
    <span>
      <a
        href={ensureProtocol(url)}
        title={getDomain(url)}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames}
        onClick={handleClick}
        dir={isRtl ? 'rtl' : 'auto'}
      >
        {content}
      </a>
      <ConfirmDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        confirmLabel={lang('Open')}
        confirmHandler={handleRedirectToUrl}
      >
        <p style={{ wordWrap: "break-word" }}>{lang('Open')} <span style={{ textDecoration: "underline" }}>{truncateUrl(url)}</span> ?</p>
      </ConfirmDialog>
    </span>
  );
};

function ensureProtocol(url?: string) {
  if (!url) {
    return undefined;
  }

  return url.includes('://') ? url : `https://${url}`;
}

function getDomain(url?: string) {
  if (!url) {
    return undefined;
  }

  const href = ensureProtocol(url);
  if (!href) {
    return undefined;
  }

  try {
    let decodedHref = decodeURI(href);

    const match = decodedHref.match(/^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i);
    if (!match) {
      return undefined;
    }
    const domain = match[1];
    decodedHref = decodedHref.replace(domain, convertPunycode(domain));

    return decodedHref;
  } catch (error) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.error('SafeLink.getDecodedUrl error ', url, error);
    }
  }

  return undefined;
}

const truncateUrl = (url: string) =>
  url.length > URL_LENGTH_LIMIT ? `${url.slice(0, URL_LENGTH_LIMIT)}…` : url;

export default memo(SafeLink);
