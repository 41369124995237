import { MouseEvent as ReactMouseEvent, ComponentPropsWithRef } from 'react';

import React, {
    FC, useCallback, useState,
} from 'react';

import buildClassName from '../../util/buildClassName';

import Spinner from './Spinner';

import './Button.scss';

export type OwnProps = {
    // ref?: RefObject<HTMLButtonElement | HTMLAnchorElement>;
    type?: 'button' | 'submit' | 'reset';
    children: any;
    size?: 'default' | 'smaller' | 'tiny';
    color?: 'primary' | 'secondary' | 'gray' | 'danger' | 'translucent' | 'translucent-white' | 'dark';
    className?: string;
    round?: boolean;
    pill?: boolean;
    fluid?: boolean;
    isText?: boolean;
    isLoading?: boolean;
    ariaLabel?: string;
    href?: string;
    download?: string;
    disabled?: boolean;
    ripple?: boolean;
    faded?: boolean;
    tabIndex?: number;
    isRtl?: boolean;
    onClick?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onContextMenu?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseDown?: (e: ReactMouseEvent<HTMLButtonElement>) => void;
    onMouseEnter?: NoneToVoidFunction;
    onMouseLeave?: NoneToVoidFunction;
    onFocus?: NoneToVoidFunction;
};

// Longest animation duration;
const CLICKED_TIMEOUT = 400;

const Button: FC<OwnProps & ComponentPropsWithRef<'button'>> = React.forwardRef(({
    // ref,
    type = 'button',
    onClick,
    onContextMenu,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    children,
    size = 'default',
    color = 'primary',
    className,
    round,
    pill,
    fluid,
    isText,
    isLoading,
    ariaLabel,
    href,
    download,
    disabled,
    ripple,
    faded,
    tabIndex,
    isRtl,
}, ref) => {
    const [isClicked, setIsClicked] = useState(false);

    const fullClassName = buildClassName(
        'Button',
        className,
        size,
        color,
        round && 'round',
        pill && 'pill',
        fluid && 'fluid',
        disabled && 'disabled',
        isText && 'text',
        isLoading && 'loading',
        faded && 'faded',
        isClicked && 'clicked',
    );

    const handleClick = useCallback((e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!disabled && onClick) {
            onClick(e);
        }

        setIsClicked(true);
        setTimeout(() => {
            setIsClicked(false);
        }, CLICKED_TIMEOUT);
    }, [disabled, onClick]);

    const handleMouseDown = useCallback((e: ReactMouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!disabled && onMouseDown) {
            onMouseDown(e);
        }
    }, [onMouseDown, disabled]);

    if (href) {
        return (
            <a
                ref={ref as any}
                className={fullClassName}
                href={href}
                title={ariaLabel}
                download={download}
                tabIndex={tabIndex}
                dir={isRtl ? 'rtl' : undefined}
            >
                {children}
            </a>
        );
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            ref={ref}
            type={type}
            className={fullClassName}
            onClick={handleClick}
            onContextMenu={onContextMenu}
            onMouseDown={handleMouseDown}
            onMouseEnter={onMouseEnter && !disabled ? onMouseEnter : undefined}
            onMouseLeave={onMouseLeave && !disabled ? onMouseLeave : undefined}
            onFocus={onFocus && !disabled ? onFocus : undefined}
            aria-label={ariaLabel}
            title={ariaLabel}
            tabIndex={tabIndex}
            dir={isRtl ? 'rtl' : undefined}
        >
            {isLoading ? (
                <div>
                    <span dir={isRtl ? 'auto' : undefined}>Please wait..</span>
                    <Spinner color={isText ? 'blue' : 'white'} />
                </div>
            ) : children}
        </button>
    );
});

export default Button;
