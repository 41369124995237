import React, { FC, memo } from 'react'

import { ApiMessage, ApiMessageOutgoingStatus } from '../../../api/types'

import { formatTime } from '../../../util/dateFormat'
import { formatIntegerCompact } from '../../../util/textFormat'

import MessageOutgoingStatus from '../../common/MessageOutgoingStatus'
import renderText from '../../common/helpers/renderText'

import './MessageMeta.scss'

type OwnProps = {
  message: ApiMessage
  outgoingStatus?: ApiMessageOutgoingStatus
  signature?: string
  onClick: () => void
}

const MessageMeta: FC<OwnProps> = ({
  message,
  outgoingStatus,
  signature,
  onClick,
}) => {
  return (
    <div className="MessageMeta" onClick={onClick}>
      {Boolean(message.views) && (
        <>
          <span className="message-views">
            {formatIntegerCompact(message.views!)}
          </span>
          <i className="icon-channelviews" />
        </>
      )}
      {signature && (
        <span className="message-signature">{renderText(signature)}</span>
      )}
      <span className="message-time">
        {message.isEdited && <>{message.isEdited && `ред. `}</>}
        {formatTime(message.date * 1000)}
      </span>
      {outgoingStatus && <MessageOutgoingStatus status={outgoingStatus} />}
    </div>
  )
}

export default memo(MessageMeta)
