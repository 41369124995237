//@ts-ignore
import TelegramLoginButton from 'react-telegram-login'
import React, { FC, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { authTokenVar, refreshTokenVar } from '../../cache'
import styled from 'styled-components'

import { env } from '../../env'

if (!env.REACT_APP_AUTH_BOT_NAME) {
    throw new Error('REACT_APP_AUTH_BOT_NAME env variable required')
}

if (!env.REACT_APP_DOMAIN) {
    throw new Error('REACT_APP_DOMAIN env variable required')
}

const REACT_APP_AUTH_BOT_NAME = env.REACT_APP_AUTH_BOT_NAME
const REACT_APP_AUTH_REDIRECT_URL = (env.BACKEND_API_URL ? env.BACKEND_API_URL : env.REACT_APP_DOMAIN) + '/api/auth/complete/telegram'

const AuthContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Auth: FC = () => {
    const client = useApolloClient()
    useEffect(() => {
        //get access_token and refresh_token from url parameters
        const urlParams = new URLSearchParams(window.location.search)
        const accessToken = urlParams.get('access_token')
        const refreshToken = urlParams.get('refresh_token')

        if (!accessToken || !refreshToken) return

        authTokenVar(accessToken)
        refreshTokenVar(refreshToken)

        // Redirect to path with previous params (e.g. chat id)
        const prevUrl = new URL(document.referrer);
        const redirectUrl = prevUrl.origin === window.location.origin && !prevUrl.search?.includes("token") ? prevUrl.href : window.location.origin;
        window.history.replaceState({}, '', window.location.origin);
        window.location.replace(redirectUrl);
    }, [client])

    return (
        <AuthContainer>
            <TelegramLoginButton
                lang='ru'
                dataAuthUrl={REACT_APP_AUTH_REDIRECT_URL}
                botName={REACT_APP_AUTH_BOT_NAME}
            />
        </AuthContainer>
    )
}

export default Auth
