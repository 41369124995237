import React, {
  FC, memo, useCallback, useEffect, useState,
} from 'react';
import { MessageListType } from '../../../global/types';
import { ApiMessage } from '../../../api/types';
import { IAlbum, IAnchorPosition } from '../../../types';
import { disableScrolling, enableScrolling } from '../../../util/scrollLock';
import useFlag from '../../../hooks/useFlag';
import MessageContextMenu from './MessageContextMenu';
import { cache, editingId } from '../../../cache';
import useShowTransition from '../../../hooks/useShowTransition';
import { useRemoveMessagesMutation } from '../../../graphql/schema';
export type OwnProps = {
  isOpen: boolean;
  message: ApiMessage;
  album?: IAlbum;
  anchor: IAnchorPosition;
  messageListType: MessageListType;
  onClose: () => void;
  onCloseAnimationEnd: () => void;
};

type StateProps = {
  noOptions?: boolean;
  canSendNow?: boolean;
  canReschedule?: boolean;
  canReply?: boolean;
  canPin?: boolean;
  canUnpin?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  canForward?: boolean;
  canFaveSticker?: boolean;
  canUnfaveSticker?: boolean;
  canCopy?: boolean;
  canCopyLink?: boolean;
  canSelect?: boolean;
};

const noop = () => { }

// type DispatchProps = Pick<GlobalActions, (
//   'setReplyingToId' | 'setEditingId' | 'pinMessage' | 'openForwardMenu' |
//   'faveSticker' | 'unfaveSticker' | 'toggleMessageSelection' | 'sendScheduledMessages' | 'rescheduleMessage' |
//   'loadMessageLink'
// )>;

const setReplyingToId = (obj: any) => void console.log('set replying to id');
const pinMessage = (obj: any) => void console.log('pin message');
const openForwardMenu = (obj: any) => void console.log('open forward menu');
const faveSticker = (obj: any) => void console.log('fave sticker');
const unfaveSticker = (obj: any) => void console.log('unfave sticker');
const toggleMessageSelection = (obj: any) => void console.log('toggle message selection');
const sendScheduledMessages = (obj: any) => void console.log('send scheduled messages');
const rescheduleMessage = (obj: any) => void console.log('reschedule message');
const loadMessageLink = (obj: any) => void console.log('loadMessage link');

const ContextMenuContainer: FC<OwnProps & StateProps> = ({
  isOpen,
  messageListType,
  message,
  album,
  anchor,
  onClose,
  onCloseAnimationEnd,
  noOptions,
  canSendNow,
  canReschedule,
  canReply,
  canPin,
  canUnpin,
  canDelete = true,
  canEdit = true,
  canForward,
  canFaveSticker,
  canUnfaveSticker,
  canCopy,
  canCopyLink,
  canSelect,
  // setReplyingToId,
  // setEditingId,
  // pinMessage,
  // openForwardMenu,
  // faveSticker,
  // unfaveSticker,
  // toggleMessageSelection,
  // sendScheduledMessages,
  // rescheduleMessage,
  // loadMessageLink,
}) => {
  const { transitionClassNames } = useShowTransition(isOpen, onCloseAnimationEnd, undefined, false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [isCalendarOpen, openCalendar, closeCalendar] = useFlag();
  const [removeMessage] = useRemoveMessagesMutation();
  const handleDelete = useCallback(() => {
    removeMessage({ variables: { input: { chatId: message.chatId, messageIds: [message.id] } } }).then(reponse => {
      const responseArray = reponse.data?.removeMessages
      if (responseArray?.includes(false)) {
        alert('Could not remove messages')
      }
      else {
        const normalizedId = cache.identify({ message_id: message.id, __typename: 'messages', })
        cache.evict({
          id: normalizedId
        })
        cache.gc();
      }
    })
    closeMenu();
    // setIsDeleteModalOpen(true);
  }, [message.chatId, message.id, removeMessage]);

  const closeMenu = useCallback(() => {
    // closeMenu();
    onClose();
  }, [onClose]);

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    onClose();
  }, [onClose]);

  const closePinModal = useCallback(() => {
    setIsPinModalOpen(false);
    onClose();
  }, [onClose]);

  const handleCloseCalendar = useCallback(() => {
    closeCalendar();
    onClose();
  }, [closeCalendar, onClose]);

  const handleReply = useCallback(() => {
    setReplyingToId({ messageId: message.id });
    closeMenu();
  }, [setReplyingToId, message.id, closeMenu]);

  const handleEdit = useCallback(() => {
    editingId(message.id)
    closeMenu();
  }, [message.id, closeMenu]);

  const handlePin = useCallback(() => {
    closeMenu();
    setIsPinModalOpen(true);
  }, []);

  const handleUnpin = useCallback(() => {
    pinMessage({ messageId: message.id, isUnpin: true });
    closeMenu();
  }, [pinMessage, message.id, closeMenu]);

  const handleForward = useCallback(() => {
    closeMenu();
    if (album && album.messages) {
      const messageIds = album.messages.map(({ id }) => id);
      openForwardMenu({ fromChatId: message.chatId, messageIds });
    } else {
      openForwardMenu({ fromChatId: message.chatId, messageIds: [message.id] });
    }
  }, [openForwardMenu, message, closeMenu, album]);

  const handleFaveSticker = useCallback(() => {
    closeMenu();
    faveSticker({ sticker: message.content.sticker });
  }, [closeMenu, message.content.sticker, faveSticker]);

  const handleUnfaveSticker = useCallback(() => {
    closeMenu();
    unfaveSticker({ sticker: message.content.sticker });
  }, [closeMenu, message.content.sticker, unfaveSticker]);

  const handleSelectMessage = useCallback(() => {
    const params = album && album.messages
      ? {
        messageId: message.id,
        childMessageIds: album.messages.map(({ id }) => id),
        withShift: false,
      }
      : { messageId: message.id, withShift: false };

    toggleMessageSelection(params);
    closeMenu();
  }, [closeMenu, message.id, toggleMessageSelection, album]);

  const handleScheduledMessageSend = useCallback(() => {
    sendScheduledMessages({ chatId: message.chatId, id: message.id });
    closeMenu();
  }, [closeMenu, message.chatId, message.id, sendScheduledMessages]);

  const handleOpenCalendar = useCallback(() => {
    closeMenu();
    openCalendar();
  }, [openCalendar]);

  const handleRescheduleMessage = useCallback((date: Date) => {
    rescheduleMessage({
      chatId: message.chatId,
      messageId: message.id,
      scheduledAt: Math.round(date.getTime() / 1000),
    });
  }, [message.chatId, message.id, rescheduleMessage]);

  const handleCopyLink = useCallback(() => {
    loadMessageLink({
      messageId: message.id,
      chatId: message.chatId,
    });
    closeMenu();
  }, [closeMenu, loadMessageLink, message.chatId, message.id]);

  useEffect(() => {
    disableScrolling();

    return enableScrolling;
  }, []);

  if (noOptions) {
    closeMenu();

    return <div />;
  }

  const scheduledMaxDate = new Date();
  scheduledMaxDate.setFullYear(scheduledMaxDate.getFullYear() + 1);

  return (
    <div className={['ContextMenuContainer', transitionClassNames].join(' ')}>
      <MessageContextMenu
        message={message}
        isOpen={isOpen}
        anchor={anchor}
        canSendNow={canSendNow}
        canReschedule={canReschedule}
        canReply={canReply}
        canDelete={canDelete}
        canPin={canPin}
        canUnpin={canUnpin}
        canEdit={canEdit}
        canForward={canForward}
        canFaveSticker={canFaveSticker}
        canUnfaveSticker={canUnfaveSticker}
        canCopy={canCopy}
        canCopyLink={canCopyLink}
        canSelect={canSelect}
        onReply={handleReply}
        onEdit={handleEdit}
        onPin={handlePin}
        onUnpin={handleUnpin}
        onForward={handleForward}
        onDelete={handleDelete}
        onFaveSticker={handleFaveSticker}
        onUnfaveSticker={handleUnfaveSticker}
        onSelect={handleSelectMessage}
        onSend={handleScheduledMessageSend}
        onReschedule={noop}
        onClose={closeMenu}
        onCopyLink={handleCopyLink}
      />
      {/* <DeleteMessageModal
        isOpen={isDeleteModalOpen}
        isSchedule={messageListType === 'scheduled'}
        onClose={closeDeleteModal}
        album={album}
        message={message}
      />
      <PinMessageModal
        isOpen={isPinModalOpen}
        messageId={message.id}
        chatId={message.chatId}
        onClose={closePinModal}
      /> */}
      {/* <CalendarModal
        isOpen={isCalendarOpen}
        withTimePicker
        selectedAt={message.date * 1000}
        maxAt={getDayStartAt(scheduledMaxDate)}
        isFutureMode
        onClose={handleCloseCalendar}
        onSubmit={handleRescheduleMessage}
      /> */}
    </div>
  );
};

export default memo(ContextMenuContainer);
/*
export default memo(withGlobal<OwnProps>(
  (global, { message, messageListType }): StateProps => {
    const { threadId } = selectCurrentMessageList(global) || {};
    const {
      noOptions,
      canReply,
      canPin,
      canUnpin,
      canDelete,
      canEdit,
      canForward,
      canFaveSticker,
      canUnfaveSticker,
      canCopy,
      canCopyLink,
      canSelect,
    } = (threadId && selectAllowedMessageActions(global, message, threadId)) || {};
    const isPinned = messageListType === 'pinned';
    const isScheduled = messageListType === 'scheduled';

    return {
      noOptions,
      canSendNow: isScheduled,
      canReschedule: isScheduled,
      canReply: !isPinned && !isScheduled && canReply,
      canPin: !isScheduled && canPin,
      canUnpin: !isScheduled && canUnpin,
      canDelete,
      canEdit: !isPinned && canEdit,
      canForward: !isScheduled && canForward,
      canFaveSticker: !isScheduled && canFaveSticker,
      canUnfaveSticker: !isScheduled && canUnfaveSticker,
      canCopy,
      canCopyLink: !isScheduled && canCopyLink,
      canSelect,
    };
  },
  (setGlobal, actions): DispatchProps => pick(actions, [
    'setReplyingToId',
    'setEditingId',
    'pinMessage',
    'openForwardMenu',
    'faveSticker',
    'unfaveSticker',
    'toggleMessageSelection',
    'sendScheduledMessages',
    'rescheduleMessage',
    'loadMessageLink',
  ]),
)(ContextMenuContainer));
 */
