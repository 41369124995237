import { useEffect } from 'react';

export default function useBackgroundMode(
  onBlur: AnyToVoidFunction,
  onFocus: AnyToVoidFunction,
) {
  useEffect(() => {
    if (!document.hasFocus()) {
      onBlur();
    }

    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [onBlur, onFocus]);
}
