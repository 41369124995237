import { FC, memo, useCallback, useMemo } from "react";
import { TRUNCATED_SUMMARY_LENGTH, getChatTitle, getMessageSummaryText } from "../../../global/helpers";
import useGlobalSearchQuery from "../../../hooks/useGlobalSearchQuery";
import { cache, currentMessageSearchIdVar, editingId, globalSearchQueryVar } from '../../../cache';
import useLang from "../../../hooks/useLang";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import Avatar from "../../common/Avatar";
import {
  isMutedVar,
  draftVar,
  currentChatIdVar,
} from "../../../cache";
import { ApiChat } from "../../../api/types";
import {
  ALL_CHATS_PRELOAD_DISABLED,
  CHAT_HEIGHT_PX,
  CHAT_LIST_SLICE,
} from "../../../config";
import InfiniteScroll from "../../ui/InfiniteScroll";
import renderText from "../../common/helpers/renderText";
import LastMessageMeta from "../../common/LastMessageMeta";

import "./LeftSearch.scss";
import { renderMessageSummary } from '../../common/helpers/renderMessageText';
import trimText from '../../../util/trimText';

type LeftSearchProps = {
  searchQuery: string;
};

const LeftSearch: FC<LeftSearchProps> = ({ searchQuery }) => {
  const lang = useLang();
  const noop = () => { };
  const privateChatUser = undefined;

  // const { search_users, search_messages } = useGlobalSearchQuery({
  const { data: chatsData, fetchMore: fetchMoreChats } = useGlobalSearchQuery({
    searchQuery: searchQuery.trim(),
  });

  console.log({ chatsData, searchQuery });

  // @ts-ignore
  const { search_users, search_messages } = chatsData || {};

  const [orderById, orderedIds] = useMemo<[any[], number[]]>(() => {
    const chats = search_messages;
    if (!chats) return [[], []];

    const sortedChats = [...chats].sort(
      (a, b) => b.message_id! - a.message_id!
    );
    return [sortedChats, sortedChats.map((u) => u.user_id as number)];
  }, [search_messages]);

  const [viewportIds, _getMore] = useInfiniteScroll(
    // lastSyncTime ? loadMoreOfType : undefined,
    noop,
    orderedIds,
    undefined,
    // CHAT_LIST_SLICE,
    10,
    true
  );

  console.log({ viewportIds });

  const handleMessageClick = useCallback((chatId, messageId?: number) => {
    if (editingId() !== 0) {
      editingId(0);
    }
    currentChatIdVar(chatId);
    // globalSearchQueryVar('')
    currentMessageSearchIdVar(messageId);
    if (messageId) {
      const messages_query = `messages:{"where":{"user_id":{"_eq":${chatId}}}}`
      cache.evict({id: 'ROOT_QUERY', fieldName: messages_query});
      cache.gc();
    }
  }, []);

  console.log("LeftSearch", searchQuery.trim());
  console.log("messages", { search_users, search_messages });

  function renderChats() {
    console.log("RENDER CHATAS", orderById);
    const viewportOffset = orderedIds!.indexOf(viewportIds![0]);
    const pinnedOffset = viewportOffset; // + chatArrays!.pinnedChats.length;

    return (
      <div
        className="scroll-container"
        style={{
          maxHeight: 200,
        }}
      // @ts-ignore
      // style={IS_ANDROID && { height: `${orderedIds!.length * CHAT_HEIGHT_PX}px` }}
      // teactFastList
      >
        {/* {chatArrays!.pinnedChats.map(({ id }, i) => (
          <Chat
            key={id}
            teactOrderKey={i}
            chatId={id}
            isPinned
            folderId={folderId}
            isSelected={id === currentChatId && !isInDiscussionThread}
            animationType={getAnimationType(id)}
            orderDiff={orderDiffById[id]}
            // @ts-ignore
            style={`top: ${(viewportOffset + i) * CHAT_HEIGHT_PX}px;`}
          />
        ))} */}
        {orderById.map(({ message_id, mess: { chat } }, i) => (
          <div key={message_id}>{chat.first_name}</div>
          // <Chat
          //   key={chat.id}
          //   // teactOrderKey={getChatOrder(chat)}
          //   chatId={chat.id}
          //   folderId={folderId}
          //   chat={chat}
          //   isSelected={chat.id === currentChatId && !isInDiscussionThread}
          // // animationType={getAnimationType(chat.id)}
          // // orderDiff={orderDiffById[chat.id]}
          // // @ts-ignore
          // // style={{
          // //   top: `${(pinnedOffset + i) * CHAT_HEIGHT_PX}px`
          // // }}
          // />
        ))}
      </div>
    );
  }

  const getMore = useCallback(() => {
    let offsetMessageId: number | undefined = undefined;
    if (orderById?.length) {
      offsetMessageId = orderById[orderById.length - 1].lastMessage?.id!;
    }
    fetchMoreChats(offsetMessageId);
  }, [orderById, fetchMoreChats]);

  if (!chatsData) {
    return null;
  }

  return (
    <div id="LeftSearch-main">
      {search_users && search_users.length > 0 && (
        <>
          <h3 className="LeftSearch-main--header">Контакты</h3>
          <ul>
            {search_users.map((user) => (
              <li key={user.user_id} onClick={() => handleMessageClick(user.user_id)}>
                <Avatar
                  size="small"
                  user={{
                    ...user,
                    isMin: false,
                    type: "userTypeRegular",
                    phoneNumber: user.phone_number ?? "",
                    id: user.user_id,
                    username: "test",
                  }}
                  text={(user.first_name || user.last_name) ?? ""}
                />
                <strong>
                  {user.first_name} {user.last_name}
                </strong>
              </li>
            ))}
          </ul>
        </>
      )}
      {search_messages && search_messages.length > 0 && (
        <>
          <h3 className="LeftSearch-main--header">Сообщения</h3>
          <ul>
            {search_messages.map(
              (message) => {
                const { message_id, mess, mess: { chat, text } } = message;
                const chatData = {
                  ...chat,
                  title: `${chat.first_name} ${chat.last_name ?? ''}`.trim(), // @TODO: get title from db
                };
                console.log({ chatData });
                return (
                  <li
                    key={message_id}
                    onClick={() => handleMessageClick(chat.id, message_id)}
                  >
                    <div className="status">
                      <Avatar
                        size="large"
                        isSavedMessages={false}
                        chat={chatData}
                      />
                    </div>
                    <div className="info">
                      <div className="title">
                        <h3>
                          {renderText(
                            getChatTitle(lang, chatData, privateChatUser)
                          )}
                        </h3>
                        <LastMessageMeta
                          message={mess}
                          outgoingStatus={undefined}
                        />
                      </div>
                      <div className="subtitle">
                        <span>{trimText(text, TRUNCATED_SUMMARY_LENGTH)}</span>
                      </div>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default memo(LeftSearch);
