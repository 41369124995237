import createAuthRefreshLink from "./createAuthRefreshLink"
import { AUTH_STORAGE_KEY, AUTH_REFRESH_STORAGE_KEY } from "../config"
import { authTokenVar, refreshTokenVar } from "../cache"
import parseJWT from "../util/parseJWT"

import { env } from "../env"

const REACT_APP_TOKEN_REFRESH_URL =
  (env.BACKEND_API_URL ? env.BACKEND_API_URL : env.REACT_APP_DOMAIN) + "/api/auth/refresh"

interface RefreshTokenResponse {
  access_token?: string
  refresh_token?: string
}

// Custom Error Class for HTTP errors
class HttpError extends Error {
  statusCode: number
  constructor(message: string, statusCode: number) {
    super(message)
    this.name = "HttpError"
    this.statusCode = statusCode
  }
}

// Refreshes JWT token with retry functionality
const getNewToken = async (refreshToken: string, attempt: number = 0): Promise<RefreshTokenResponse> => {
  try {
    // Send GET request to refresh token endpoint with refresh_token param
    const url = new URL(REACT_APP_TOKEN_REFRESH_URL)
    url.searchParams.append("refresh_token", refreshToken)

    // Fetch the resulting URL
    const response = await fetch(url.toString())

    // Handle 403 Forbidden explicitly by throwing an error
    if (response.status === 403) {
      throw new HttpError("Access Forbidden: Unable to refresh token", 403)
    }

    // If response is OK, return the JSON data
    if (response.ok) {
      const responseData: RefreshTokenResponse = await response.json()
      return responseData
    } else {
      // For any other response code, throw to retry
      throw new HttpError(`Server responded with status: ${response.status}`, response.status)
    }
  } catch (error) {
    // We dont want to retry 403 errors
    if (error instanceof HttpError && error.statusCode === 403) {
      throw error
    }

    console.error(`Retry token refresh in 5 seconds. Attempt ${attempt}: ${error}`)

    // Retry mechanism with a 5-second delay for any error
    await new Promise((resolve) => setTimeout(resolve, 5000))
    return getNewToken(refreshToken, attempt + 1)
  }
}

export async function getRefreshedAccessTokenPromise() {
  console.log("token refresh started")
  try {
    // fetch new token

    // we must take refreshToken from localStorage instead of memory because
    // when multiple tabs are used if refresh happens in another tab
    // stored refresh token becomes stale and will trigger auth error and logout if used in refresh query
    const refresh = localStorage.getItem(AUTH_REFRESH_STORAGE_KEY)
    if (!refresh) throw new Error("Refresh impossible: missing refresh token")

    const newTokenData = await getNewToken(refresh)

    // handle result and return token
    const token = newTokenData.access_token
    const refreshToken = newTokenData.refresh_token

    authTokenVar(token)
    refreshTokenVar(refreshToken)

    if (!token) throw new Error("Server returned no token")
    return token
  } catch (error) {
    // logout, show alert or something
    console.error("error refreshing token", error)
    console.log("LOGGING OUT")

    authTokenVar(undefined)
    refreshTokenVar(undefined)
    throw error
  }
}

export default createAuthRefreshLink({
  getToken: () => localStorage.getItem(AUTH_STORAGE_KEY) || undefined,
  refreshToken: getRefreshedAccessTokenPromise,
  isTokenExpired: () => {
    const token = parseJWT(localStorage.getItem(AUTH_STORAGE_KEY) || undefined)
    if (!token) return true

    const currentNumericDate = Math.round(Date.now() / 1000)

    const remainingTime = token.exp - currentNumericDate

    return remainingTime < 60
  }
})
