import { ApiMessage } from '../../../../api/types';

import { LAYERS_TRANSITION_DURATION } from '../../../../config';
import { IS_MOBILE_SCREEN } from '../../../../util/environment';
import { getMessageMediaThumbDataUri } from '../../../../global/helpers';
import useBlur from '../../../../hooks/useBlur';
import { useEffect, useState } from "react";

export default function useBlurredMediaThumb(message: ApiMessage, fullMediaData?: string) {
  const [thumbDataUri, setThumbDataUri] = useState<string | undefined>('');
  useEffect(() => {
    if (message) {
      getMessageMediaThumbDataUri(message).then(setThumbDataUri)
    }
  }, [message])
  return useBlur(
      thumbDataUri,
      Boolean(fullMediaData),
      IS_MOBILE_SCREEN ? LAYERS_TRANSITION_DURATION : undefined,
  );
}
