import React, { FC, memo, useCallback } from 'react';

import type { ApiChat, ApiMessage, ApiUser } from '../../api/types';
import { getChatTitle, getUserFullName } from '../../global/helpers';
import { formatMediaDateTime } from '../../util/dateFormat';
import renderText from '../common/helpers/renderText';

import useLang from '../../hooks/useLang';

import Avatar from '../common/Avatar';

import './SenderInfo.scss';
import { useChatQuery, useMessagesQuery } from "../../graphql/schema";
import { DEFAULT_MEDIA_VIEWER, mediaViewerVar } from "../../cache";
import { useIntl } from "react-intl";

type OwnProps = {
  chatId?: number;
  messageId?: number;
  isAvatar?: boolean;
};

type StateProps = {
  sender?: ApiUser | ApiChat;
  message?: ApiMessage;
};

/*const ANIMATION_DURATION = 350;*/

const SenderInfo: FC<OwnProps & StateProps> = ({
 /* chatId,
  messageId,*/
  sender,
  isAvatar,
  message,
}) => {
    const intl = useIntl()

  const handleFocusMessage = useCallback(() => {
    mediaViewerVar(DEFAULT_MEDIA_VIEWER);

  /*  if (IS_SINGLE_COLUMN_LAYOUT) {
      setTimeout(() => {
        toggleChatInfo(false, { forceSyncOnIOs: true });
        focusMessage({ chatId, messageId });
      }, ANIMATION_DURATION);
    } else {
      focusMessage({ chatId, messageId });
    }*/
  }, []);

  const lang = useLang();

  if (!sender || (!message && !isAvatar)) {
    return null;
  }
  const senderTitle = getUserFullName(sender as ApiUser) || getChatTitle(lang, sender as ApiChat);
  return (
    <div className="SenderInfo" onClick={handleFocusMessage}>
      <Avatar key={sender.id} size="medium" chat={sender as ApiChat}/>
      <div className="meta">
        <div className="title" dir="auto">
          {senderTitle && renderText(senderTitle)}
        </div>
        <div className="date" dir="auto">
          {isAvatar
              ? lang('lng_mediaview_profile_photo')
              : formatMediaDateTime(intl, message!.date * 1000, true)}
        </div>
      </div>
    </div>
  );
};

export default memo<OwnProps>(({ chatId, messageId, isAvatar, ...ownProps }: OwnProps) => {
  const { data: messagesData, loading,error } = useMessagesQuery({
    variables: {
      limit: 1,
      where: {
        user_id: {
          _eq: chatId
        },
        message_id: {
          _eq: messageId
        }
      }
    }
  });
  const { data: chatData,loading:chatLoading,error:chatError } = useChatQuery(
      { variables: { id: chatId ?? 0 } }
  )
  const chat = chatData?.user
  if (isAvatar && chatId) {
    return <SenderInfo {...ownProps} />
  }
  if (error) {
    console.error('[SenderInfo][Component] message query error', error);
    return null;
  }
  if (chatError) {
    console.error('[SenderInfo][Component] chat query error', chatError);
    return null;
  }
  if (loading || chatLoading
      || !messageId || !chatId || !chat ||
      !messagesData || !Array.isArray(messagesData.messages) || messagesData.messages.length === 0) {
    return null;
  }
  return <SenderInfo {...ownProps} message={messagesData.messages[0]} sender={chat}/>
});
