import { useEffect, useMemo } from 'react';

import { ApiMessage } from '../api/types';

import { throttle } from '../util/schedulers';

const useEnsureMessage = (
  chatId: number,
  messageId?: number,
  message?: ApiMessage,
  replyOriginForId?: number,
) => {
  // const { loadMessage } = getDispatch();
  // const loadMessageThrottled = useMemo(() => {
  //   const throttled = throttle(loadMessage, 500, true);
  //   return () => {
  //     throttled({ chatId, messageId, replyOriginForId });
  //   };
  // }, [loadMessage, chatId, messageId, replyOriginForId]);

  // useEffect(() => {
  //   if (messageId && !message) {
  //     loadMessageThrottled();
  //   }
  // });
};

export default useEnsureMessage
