import { ApiMessage } from '../../../../api/types';

import { IS_CANVAS_FILTER_SUPPORTED, IS_SINGLE_COLUMN_LAYOUT } from '../../../../util/environment';
import { getMessageMediaThumbDataUri } from '../../../../global/helpers';
import useCanvasBlur from '../../../../hooks/useCanvasBlur';
import { useEffect, useState } from "react";

export default function useBlurredMediaThumbRef(message: ApiMessage, fullMediaData?: string) {
  const [thumbDataUri,setThumbDataUri] = useState<string|undefined>('');
  useEffect(() => {
    if (message) {
      getMessageMediaThumbDataUri(message).then(setThumbDataUri)
    }
  },[message])
  return useCanvasBlur(
      thumbDataUri,
      Boolean(fullMediaData),
      IS_SINGLE_COLUMN_LAYOUT && !IS_CANVAS_FILTER_SUPPORTED,
  );
}
