import { InMemoryCache, ApolloCache } from '@apollo/client'
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'
import {
  ChatsDocument,
  ChatsQuery,
  ChatsQueryVariables,
  UserChatFragment,
  UserChatFragmentDoc,
} from '../schema'
import { insertOrderedChat } from './insertOrderedChat'

export const addChatToFolder = (userId: number | undefined, tagId: number | undefined, cache: InMemoryCache | ApolloCache<any>, readField?: ReadFieldFunction) => {
  if (!userId) return

  const variables = tagId ? {
    where: {
      tags: {
        tag_id: {
          _eq: tagId
        }
      }
    }
  } : {
    where: {
      last_message_id: {
        _is_null: false
      }
    }
  }

  const chatCollection = cache.readQuery<ChatsQuery, ChatsQueryVariables>({
    query: ChatsDocument,
    variables
  })

  // chat list was not loaded to client yet
  if (!chatCollection) return


  const users = chatCollection?.users || []
  const id = cache.identify({ __typename: 'users', user_id: userId })

  const user = cache.readFragment<UserChatFragment>({
    id,
    fragmentName: 'UserChat',
    fragment: UserChatFragmentDoc,
  })

  if (!user) return

  const res = insertOrderedChat(user, users, readField)

  cache.writeQuery<ChatsQuery, ChatsQueryVariables>({
    query: ChatsDocument,
    variables,
    data: {
      __typename: 'Query',
      users: res
    },
  })
}