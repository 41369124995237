import { useCallback } from 'react'
import { Message } from 'telegram-typings'
import { useReactiveVar } from '@apollo/client';
import { currentChatIdVar, editingId } from '../cache';
import { useEditMessageMutation } from '../graphql/schema';
import { ApiFormattedText } from '../api/types'
export interface EditMessageParams {
    formattedText?: ApiFormattedText,
    isCaption: boolean,
    chatId: number,
    messageId: number
}

const useEditMessage = () => {
    const [editMessageMutation] = useEditMessageMutation()
    const userId = useReactiveVar(currentChatIdVar);

    return useCallback((params: EditMessageParams) => {
        if (!userId) return
        editMessageMutation({
            variables: {
                input: {
                    chatId: params.chatId,
                    messageId: params.messageId,
                    text: params.formattedText?.text as string,
                    entities: params.formattedText?.entities,
                    isCaption: params.isCaption,
                }
            },
            optimisticResponse: {
                __typename: 'Mutation',
                editMessage: {
                    __typename: "messages",
                    message_id: params.messageId,
                    user_id: userId,
                    mess: {
                        message_id: params.messageId,
                        date: Date.now() / 1000,
                        text: params.formattedText?.text,
                    } as Message,
                    created: Date.now() / 1000,
                }
            },
            onError: (error) => {
                var text = 'Не удалось отредактировать сообщение\nОфициальная причина: ' + error
                alert(text)
            }
        }).then(() => {
            editingId(0)
        })
    }, [userId, editMessageMutation])
}

export default useEditMessage
