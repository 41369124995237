import {
  NewChatDocument,
  NewChatQuery,
  NewChatQueryVariables,
  MessageFragmentDoc,
  Messages,
  MessagesDocument,
  MessagesQuery,
  MessagesQueryVariables,
  useNewMessageSubscription,
  UserChatLastMessageFragment,
  UserChatLastMessageFragmentDoc
} from '../graphql/schema'

import { addChatToFolder } from '../graphql/typePolicies/addChatToFolder'

/**
 * This hook listens to new messages and updates cache
 */
const useListenToNewMessages = () => {
  useNewMessageSubscription({
    onData: async ({ data, client }) => {
      const cache = client.cache

      const newMessage = data.data?.newMessage
      if (!newMessage) return

      const chatId = newMessage.user_id
      if (!chatId) return

      const messageId: number | undefined = newMessage.message_id
      if (!messageId) return

      const message = cache.readFragment<Messages>({
        fragment: MessageFragmentDoc,
        id: cache.identify(newMessage)
      })

      if (!message) throw new Error('no message found in cache ' + JSON.stringify(newMessage))

      // // adding message to last_message of a chat

      // if chat exitsts it will be taken from cache
      // if not loaded from server
      const { data: chatData } = await client.query<NewChatQuery, NewChatQueryVariables>({
        query: NewChatDocument,
        variables: {
          id: chatId
        },
      })

      const chat = chatData?.user
      if (!chat) return

      // it is important to update last_message_id and lastMessage before inserting chat to folder
      // because chat order depends on last_message_id
      cache.writeFragment<UserChatLastMessageFragment>({
        id: cache.identify({
          __typename: 'users',
          user_id: chatId
        }),
        fragment: UserChatLastMessageFragmentDoc,
        fragmentName: 'UserChatLastMessage',
        data: {
          id: chatId,
          __typename: 'users',
          user_id: chatId,
          last_message_id: messageId,
          lastMessage: message,
          unread: !message?.staff_id
        }
      })

      // // adding message to messages list of a chat
      cache.writeQuery<MessagesQuery, MessagesQueryVariables>({
        query: MessagesDocument,
        data: {
          __typename: 'Query',
          messages: [message]
        },
        variables: {
          where: {
            user_id: {
              _eq: chatId
            }
          }
        }
      })

      // add chat to all folders it belongs to
      chat.tags?.forEach(t => {
        addChatToFolder(chat.user_id, t.tag_id, client.cache)
      })

      // add chat to default folder
      addChatToFolder(chat.user_id, undefined, client.cache)
    }
  })
}

export default useListenToNewMessages