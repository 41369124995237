import React, { FC, useCallback } from 'react';
// import { withGlobal } from '../../lib/teact/teactn';

import { GlobalActions } from '../../global/types';
import { ApiMessage } from '../../api/types';

import { pick } from '../../util/iteratees';
import buildClassName from '../../util/buildClassName';

import Link from '../ui/Link';

type OwnProps = {
  className?: string;
  message?: ApiMessage;
  children: any;
};

const focusMessage = () => void console.log('focusMessage')

const MessageLink: FC<OwnProps> = ({
  className, message, children
}) => {
  const handleMessageClick = useCallback((): void => {
    if (message) {
      // focusMessage({ chatId: message.chatId, messageId: message.id });
      focusMessage();
    }
  }, [focusMessage, message]);

  if (!message) {
    return children;
  }

  return (
    <Link className={buildClassName('MessageLink', className)} onClick={handleMessageClick}>{children}</Link>
  );
};

export default MessageLink