import { InMemoryCache } from '@apollo/client'
import {
    ChatTagsDocument,
    ChatTagsQuery,
    ChatTagsQueryVariables
} from '../schema'

export const removeTagFromChat = (userId: number | undefined, tagId: number | undefined, cache: InMemoryCache) => {
    if (!userId || !tagId) return

    // remove tag from chat
    const chat = cache.readQuery<ChatTagsQuery, ChatTagsQueryVariables>({
        query: ChatTagsDocument,
        variables: {
            id: userId
        }
    })

    if (!chat || !chat.user) return
    // if (!chat.user.tags.find(t => t.tag_id === tagId && t.user_id === userId)) {
    //     return
    // }

    cache.writeQuery<ChatTagsQuery, ChatTagsQueryVariables>({
        query: ChatTagsDocument,
        data: {
            __typename: 'Query',
            user: {
                ...chat?.user,
                tags: chat.user.tags.filter(t => t.tag_id !== tagId)
            }
        }
    })
}