import { ApiMediaFormat, ApiOnProgress, ApiParsedMedia, ApiPreparedMedia, } from '../api/types';

import {  DEBUG, } from '../config';
// import { callApi, cancelApiProgress } from '../api/gramjs';
import * as cacheApi from './cacheApi';
import { fetchBlob } from './files';
import { IS_OPUS_SUPPORTED, IS_PROGRESSIVE_SUPPORTED, isWebpSupported } from './environment';
import { authTokenVar } from "../cache";
// import { oggToWav } from './oggToWav';
// import { webpToPng } from './webpToPng';

import { env } from '../env'

const asCacheApiType = {
  [ApiMediaFormat.BlobUrl]: cacheApi.Type.Blob,
  [ApiMediaFormat.Lottie]: cacheApi.Type.Blob,
  [ApiMediaFormat.Progressive]: undefined,
  [ApiMediaFormat.Stream]: undefined,
};

const PROGRESSIVE_URL_PREFIX = './progressive/';
const filesUrl = env.REACT_APP_DOMAIN + '/file'

const memoryCache = new Map<string, ApiPreparedMedia>();
const fetchPromises = new Map<string, Promise<ApiPreparedMedia | undefined>>();
const progressCallbacks = new Map<string, Map<string, ApiOnProgress>>();
const cancellableCallbacks = new Map<string, ApiOnProgress>();

type DownloadResult = {
  prepared: string;
  arrayBuffer: ArrayBuffer | undefined;
  mimeType: string | undefined;
  fullSize: number | undefined;
}

const downloadMedia = async (hash: string, mediaFormat: ApiMediaFormat, onProgress: ApiOnProgress, signal?: AbortSignal): Promise<DownloadResult> => {
  const url = new URL(filesUrl);
  url.searchParams.append('file_id',hash)
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    const token: string = authTokenVar() || ""
    url.searchParams.append('auth', token)
  }

  const fetchOptions = {
    signal: signal,
  };
  const result = await window.fetch(url.href, fetchOptions)

  if (!result.ok) {
    throw new Error('Failed to fetch media: ${result.statusText}')
  }
  const contentLength = +result.headers.get('Content-Length')!
  const reader = result.body?.getReader()
  if (!reader) {
    throw new Error('Failed to get reader from result body');
  }

  let receivedLength = 0;
  const chunks: Uint8Array[] = [];

  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    if (value) {
      chunks.push(value);
      receivedLength += value.length;

      if (onProgress) {
        const progress = receivedLength / contentLength
        onProgress(progress)
      }
    }
  }
  const blob = new Blob(chunks)
  const arrayBuffer = await blob.arrayBuffer()
  onProgress(1)

  return {
    prepared: URL.createObjectURL(blob),
    arrayBuffer: arrayBuffer,
    mimeType: blob.type,
    fullSize: blob.size,
  }
}

export function fetch<T extends ApiMediaFormat>(
  url: string,
  mediaFormat: T,
  isHtmlAllowed = false,
  onProgress?: ApiOnProgress,
  callbackUniqueId?: string,
  signal?: AbortSignal,
): Promise<ApiPreparedMedia> {
  if (mediaFormat === ApiMediaFormat.Progressive) {
    return (
      IS_PROGRESSIVE_SUPPORTED
        ? getProgressive(url)
        : fetch(url, ApiMediaFormat.BlobUrl, isHtmlAllowed, onProgress, callbackUniqueId)
    ) as Promise<ApiPreparedMedia>;
  }

  if (!fetchPromises.has(url)) {
    const activeCallbacks = progressCallbacks.get(url);
    const promise = fetchFromCacheOrRemote(url, mediaFormat, isHtmlAllowed, activeCallbacks? (progress) => {
      if (activeCallbacks) {
        const callback = callbackUniqueId? activeCallbacks.get(callbackUniqueId) : undefined;
        callback && callback(progress);
      }
    } : undefined, signal)
      .catch((err) => {
        if (DEBUG) {
          // eslint-disable-next-line no-console
          console.warn(err);
        }

        return undefined;
      })
      .finally(() => {
        fetchPromises.delete(url);
        progressCallbacks.delete(url);
        cancellableCallbacks.delete(url);
      });

    fetchPromises.set(url, promise);
  }

  if (onProgress && callbackUniqueId) {
    let activeCallbacks = progressCallbacks.get(url);
    if (!activeCallbacks) {
      console.log('no cb')
      activeCallbacks = new Map<string, ApiOnProgress>();
      progressCallbacks.set(url, activeCallbacks);
    }
    activeCallbacks.set(callbackUniqueId, onProgress);
  }
  return fetchPromises.get(url) as Promise<ApiPreparedMedia>;
}

export function getFromMemory(url: string) {
  return memoryCache.get(url) as ApiPreparedMedia;
}

export function cancelProgress(progressCallback: ApiOnProgress) {
  progressCallbacks.forEach((map, url) => {
    map.forEach((callback) => {
      if (callback === progressCallback) {
        const parentCallback = cancellableCallbacks.get(url);
        if (!parentCallback) return;

        // cancelApiProgress(parentCallback);
        cancellableCallbacks.delete(url);
        progressCallbacks.delete(url);
      }
    });
  });
}

export function removeCallback(url: string, callbackUniqueId: string) {
  const callbacks = progressCallbacks.get(url);
  if (!callbacks) return;
  callbacks.delete(callbackUniqueId);
}

function getProgressive(url: string) {
  const progressiveUrl = `${PROGRESSIVE_URL_PREFIX}${url}`;

  memoryCache.set(url, progressiveUrl);

  return Promise.resolve(progressiveUrl);
}

async function fetchFromCacheOrRemote(
  url: string, mediaFormat: ApiMediaFormat, isHtmlAllowed: boolean, onProgresscb?: ApiOnProgress, signal?: AbortSignal
) {
  // if (!MEDIA_CACHE_DISABLED) {
  //   const cacheName = url.startsWith('avatar') ? MEDIA_CACHE_NAME_AVATARS : MEDIA_CACHE_NAME;
  //   const cached = await cacheApi.fetch(cacheName, url, asCacheApiType[mediaFormat]!, isHtmlAllowed);

  //   if (cached) {
  //     let media = cached;

  //     if (cached.type === 'audio/ogg' && !IS_OPUS_SUPPORTED) {
  //       // media = await oggToWav(media);
  //     }

  //     if (cached.type === 'image/webp' && !isWebpSupported() && media) {
  //       // const mediaPng = await webpToPng(url, media);
  //       // if (mediaPng) {
  //         // media = mediaPng;
  //       // }
  //     }

  //     const prepared = prepareMedia(media);

  //     memoryCache.set(url, prepared);

  //     return prepared;
  //   }
  // }

  if (mediaFormat === ApiMediaFormat.Stream) {
    const mediaSource = new MediaSource();
    const streamUrl = URL.createObjectURL(mediaSource);
    let isOpen = false;

    mediaSource.addEventListener('sourceopen', () => {
      if (isOpen) {
        return;
      }
      isOpen = true;

      const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');

      const onProgress = makeOnProgress(url, mediaSource, sourceBuffer);
      cancellableCallbacks.set(url, onProgress);

      // void callApi('downloadMedia', { url, mediaFormat }, onProgress);
      downloadMedia(url, mediaFormat, onProgress, signal)
    });

    memoryCache.set(url, streamUrl);
    return streamUrl;
  }

  const onProgress = onProgresscb || makeOnProgress(url);
  cancellableCallbacks.set(url, onProgress);

  // const remote = await callApi('downloadMedia', { url, mediaFormat, isHtmlAllowed }, onProgress);
  const remote = await downloadMedia(url, mediaFormat, onProgress, signal)
  if (!remote) {
    throw new Error(`Failed to fetch media ${url}`);
  }

  let { prepared, mimeType } = remote;

  if (mimeType === 'audio/ogg' && !IS_OPUS_SUPPORTED) {
    const blob = await fetchBlob(prepared as string);
    URL.revokeObjectURL(prepared as string);
    // const media = await oggToWav(blob);
    // prepared = prepareMedia(media);
    // mimeType = media.type;
  }

  if (mimeType === 'image/webp' && !isWebpSupported()) {
    const blob = await fetchBlob(prepared as string);
    URL.revokeObjectURL(prepared as string);
    // const media = await webpToPng(url, blob);
    // if (media) {
    // prepared = prepareMedia(media);
    // }
  }

  memoryCache.set(url, prepared);

  return prepared;
}

function makeOnProgress(url: string, mediaSource?: MediaSource, sourceBuffer?: SourceBuffer) {
  const onProgress: ApiOnProgress = (progress: number, arrayBuffer: ArrayBuffer) => {
    console.log('download onprogress')
    progressCallbacks.get(url)?.forEach((callback) => {
      callback(progress);
      if (callback.isCanceled) onProgress.isCanceled = true;
    });

    if (progress === 1) {
      mediaSource?.endOfStream();
    }

    if (!arrayBuffer) {
      return;
    }

    sourceBuffer?.appendBuffer(arrayBuffer);
  };

  return onProgress;
}

function prepareMedia(mediaData: Exclude<ApiParsedMedia, ArrayBuffer>): ApiPreparedMedia {
  if (mediaData instanceof Blob) {
    return URL.createObjectURL(mediaData);
  }

  return mediaData;
}

/*if (IS_PROGRESSIVE_SUPPORTED) {
  navigator.serviceWorker.addEventListener('message', async (e) => {
  const { type, messageId, params } = e.data as {
  type: string;
  messageId: string;
  params: { url: string; start: number; end: number };
};*/

//     if (type !== 'requestPart') {
//       return;
//     }

//     // const result = await callApi('downloadMedia', { mediaFormat: ApiMediaFormat.Progressive, ...params });
//     const result = await downloadMedia(params.url, ApiMediaFormat.Progressive);
//     if (!result) {
//       return;
//     }

//     const { arrayBuffer, mimeType, fullSize } = result;

//     navigator.serviceWorker.controller!.postMessage({
//       type: 'partResponse',
//       messageId,
//       result: {
//         arrayBuffer,
//         mimeType,
//         fullSize,
//       },
//     }, [arrayBuffer!]);
//   });
// }
