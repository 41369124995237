import { MouseEvent as ReactMouseEvent } from 'react';
import React, { FC, useEffect, useCallback, memo } from 'react';
//import { withGlobal } from '../../lib/teact/teactn';

import { ApiChat, ApiTypingStatus } from '../../api/types';
import { GlobalActions, GlobalState } from '../../global/types';
import { MediaViewerOrigin } from '../../types';
import { useChatQuery } from '../../graphql/schema'

import {
  getChatTypeString,
  getChatTitle,
  isChatSuperGroup,
} from '../../global/helpers';
//import { selectChat, selectChatMessages, selectChatOnlineCount } from '../../modules/selectors';
import renderText from './helpers/renderText';
import { pick } from '../../util/iteratees';
import useLang, { LangFn } from '../../hooks/useLang';

import Avatar from './Avatar';
import VerifiedIcon from './VerifiedIcon';
import TypingStatus from './TypingStatus';

type OwnProps = {
  chatId: number;
  typingStatus?: ApiTypingStatus;
  avatarSize?: 'small' | 'medium' | 'large' | 'jumbo';
  withMediaViewer?: boolean;
  withUsername?: boolean;
  withFullInfo?: boolean;
  withUpdatingStatus?: boolean;
  withChatType?: boolean;
};

type StateProps = {
  chat?: ApiChat;
  onlineCount?: number;
  areMessagesLoaded: boolean;
  onClick?: () => void;
} & Pick<GlobalState, 'lastSyncTime'>;

//type DispatchProps = Pick<GlobalActions, 'loadFullChat' | 'openMediaViewer'>;

const GroupChatInfo: FC<OwnProps & StateProps> = ({
  typingStatus,
  avatarSize = 'medium',
  withMediaViewer,
  withUsername,
  withFullInfo,
  withUpdatingStatus,
  withChatType,
  // chat,
  chatId,
  onlineCount,
  areMessagesLoaded,
  lastSyncTime,
  onClick,
  //loadFullChat,
  //openMediaViewer,
}) => {
  const { data: chatData } = useChatQuery(
    { variables: { id: chatId } }
  )
  const chat = chatData?.user

  // console.log('reference chat query', chatId, chatData)

  const isSuperGroup = true //chat && isChatSuperGroup(chat);
  // const { isMin, isRestricted } = chat || {};
  const isMin = false
  const isRestricted = false

  useEffect(() => {
    if (chatId && !isMin && withFullInfo && lastSyncTime) {
      //loadFullChat({ chatId });
    }
  }, [chatId, isMin, lastSyncTime, withFullInfo, /*loadFullChat,*/ isSuperGroup]);

  const handleAvatarViewerOpen = useCallback((e: ReactMouseEvent<HTMLDivElement, MouseEvent>, hasPhoto: boolean) => {
    if (chat && hasPhoto) {
      e.stopPropagation();
      // openMediaViewer({
      //   avatarOwnerId: chat.id,
      //   origin: avatarSize === 'jumbo' ? MediaViewerOrigin.ProfileAvatar : MediaViewerOrigin.MiddleHeaderAvatar,
      // });
    }
  }, [chat, avatarSize, /*openMediaViewer*/]);

  const lang = useLang();

  if (!chat) {
    return <div />;
  }

  function renderStatusOrTyping() {
    if (withUpdatingStatus && !areMessagesLoaded && !isRestricted) {
      return (
        <span className="status">{lang('Updating')}</span>
      );
    }

    if (!chat) {
      return undefined;
    }

    if (typingStatus) {
      return <TypingStatus typingStatus={typingStatus} />;
    }

    if (withChatType) {
      return (
        <div className="status">{lang('Chat')}</div>
      );
    }

    const handle = withUsername ? chat.username : undefined;
    const groupStatus = getGroupStatus(lang, chat);
    const onlineStatus = onlineCount ? `, ${lang('OnlineCount', onlineCount, 'i')}` : undefined;

    return (
      <div className="status">
        {handle && <span className="handle">{handle}</span>}
        <span className="group-status">{groupStatus}</span>
        {onlineStatus && <span className="online-status">{onlineStatus}</span>}
      </div>
    );
  }

  return (
    <div className="ChatInfo">
      <div className='info-wrapper' onClick={onClick}>
        <Avatar
          key={chat.id}
          size={avatarSize}
          chat={chat}
          // onClick={withMediaViewer ? handleAvatarViewerOpen : undefined}  TODO when profile will have information
        />
        <div className="info">
          <div className="title">
            <h3>{renderText(getChatTitle(lang, chat))}</h3>
            {/* <VerifiedIcon /> */}
          </div>
          {renderStatusOrTyping()}
        </div>
      </div>
    </div>
  );
};

function getGroupStatus(lang: LangFn, chat: ApiChat) {
  const chatTypeString = lang(getChatTypeString(chat));
  const { membersCount } = chat;

  if (chat.isRestricted) {
    return chatTypeString === 'Channel' ? 'channel is inaccessible' : 'group is inaccessible';
  }

  if (!membersCount) {
    return chatTypeString;
  }

  return chatTypeString === 'Channel'
    ? lang('Subscribers', membersCount, 'i')
    : lang('Members', membersCount, 'i');
}

export default memo(GroupChatInfo);

/*
export default memo(withGlobal<OwnProps>(
  (global, { chatId }): StateProps => {
    const { lastSyncTime } = global;
    const chat = selectChat(global, chatId);
    const onlineCount = chat ? selectChatOnlineCount(global, chat) : undefined;
    const areMessagesLoaded = Boolean(selectChatMessages(global, chatId));

    return {
      lastSyncTime, chat, onlineCount, areMessagesLoaded,
    };
  },
  (setGlobal, actions): DispatchProps => pick(actions, ['loadFullChat', 'openMediaViewer']),
)(GroupChatInfo));
 */
