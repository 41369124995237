interface Hasuraoibot {
  'x-hasura-allowed-roles': string[];
  'x-hasura-default-role': string;
  'x-hasura-user-id': string;
}

interface JWTPayload {
  sub: string;
  exp: number;
  sid: string;
  hasura_oibot: Hasuraoibot;
}

const parseJWT = (token?: string) => {
  if (!token) return undefined
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const payload = JSON.parse(window.atob(base64))
    if (payload.sub) {
      return payload as JWTPayload
    }
  } catch (e) {
    console.error('JWT parse error', e)
    return undefined
  }
}

export default parseJWT