import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';
import App from './App'
import reportWebVitals from './reportWebVitals'
import { IntlProvider } from 'react-intl'
import switchTheme from './util/switchTheme'
import client from './graphql'
import {
  ApolloProvider,
} from "@apollo/client";
import './styles/index.scss';

switchTheme('dark', false)


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <GlobalStyle /> */}
      <ApolloProvider client={client}>
        <IntlProvider locale='ru' defaultLocale='ru'>
          <App />
        </IntlProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
