import React, { FC, memo } from 'react';

import { ApiMessage, ApiMessageOutgoingStatus } from '../../api/types';

import { formatPastTimeShort } from '../../util/dateFormat';
import { useIntl } from 'react-intl'

import MessageOutgoingStatus from './MessageOutgoingStatus';

import './LastMessageMeta.scss';

type OwnProps = {
  message: ApiMessage;
  outgoingStatus?: ApiMessageOutgoingStatus;
};

const LastMessageMeta: FC<OwnProps> = ({ message, outgoingStatus }) => {
  const intl = useIntl()
  return (
    <div className="LastMessageMeta">
      {outgoingStatus && (
        <MessageOutgoingStatus status={outgoingStatus} />
      )}
      <span className="time">{formatPastTimeShort(intl, message.date * 1000, true)}</span>
    </div>
  );
};

export default memo(LastMessageMeta);
