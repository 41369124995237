import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { currentChatIdVar } from "../cache";
import { useChangeIsReadStateMutation } from "../graphql/schema";

const useChangeIsReadState = () => {
  const [changeIsReadStateMutation] = useChangeIsReadStateMutation();
  const chatId = useReactiveVar(currentChatIdVar);

  return useCallback((unread: boolean = false) => {
    if (!chatId) return;

    changeIsReadStateMutation({
      variables: {
        userId: chatId,
        unread
      },
    });
  }, [chatId, changeIsReadStateMutation]);
};

export default useChangeIsReadState;
