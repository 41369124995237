import React, { FC, useEffect, useMemo, useState } from 'react';

import { ApiChatFolder } from '../../../api/types';
import { currentUserIdVar, chatFolderVar, currentChatIdVar, onlyUnreadVar, onlyMyMessagesVar } from '../../../cache';
import { useReactiveVar } from '@apollo/client';

import buildClassName from '../../../util/buildClassName';
import useLang from '../../../hooks/useLang';
import { useChatFoldersQuery } from '../../../graphql/schema';

import TabList from '../../ui/TabList';
import ChatList from './ChatList';
import Checkbox from '../../ui/Checkbox';

const setActiveChatFolder = (folder?: number) => void console.log(`set chat folrder to ${folder}`)

const INFO_THROTTLE = 3000;
const SAVED_MESSAGES_HOTKEY = '0';

const empty: any[] = []

const defaultFolders: ApiChatFolder[] = [
  {
    id: -1,
    title: 'Все',
    includedChatIds: [],
    excludedChatIds: []
  }
]
// set default folder
chatFolderVar(defaultFolders[0])

const ChatFolders: FC = () => {
  const { data } = useChatFoldersQuery()
  const lang = useLang();
  const currentUserId = useReactiveVar(currentUserIdVar)
  const currentFolder = useReactiveVar(chatFolderVar)
  const [needRefetchFolderChats, setNeedRefetchFolderChats] = useState(false)

  const folderTags = data?.tags ?? []

  const displayedFolders = useMemo(() => {
    const foldersFromTags = folderTags.map(tag => ({
      id: tag.tag_id,
      title: tag.tag_name ?? '',
      includedChatIds: [],
      excludedChatIds: [],
    }))

    return [...defaultFolders, ...foldersFromTags]
  }, [folderTags])

  const folderTabs = useMemo(() => {
    if (!displayedFolders || !displayedFolders.length) {
      return undefined;
    }

    return [
      ...displayedFolders.map((folder) => ({
        title: folder.title,
      })),
    ];
  }, [displayedFolders]);

  const foldersFilteredMap = useMemo(() => {
    const foldersMap = new Map<Number, {'unread':Boolean, 'my': Boolean}>()
    displayedFolders.forEach((folder) => foldersMap.set(folder.id, {'unread': true, 'my': false}))
    return foldersMap
  }, [displayedFolders])

  const orderedFolderIds = empty

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.code.startsWith('Digit') && folderTabs) {
        const [, digit] = e.code.match(/Digit(\d)/) || [];
        if (!digit) return;

        if (digit === SAVED_MESSAGES_HOTKEY) {
          currentChatIdVar(currentUserId);
          return;
        }

        const folder = Number(digit) - 1;
        if (folder > folderTabs.length - 1) return;

        setActiveChatFolder(folder);
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  });

  const openTabIndex = displayedFolders.findIndex(f => f.id === currentFolder?.id)

  const onlyUnread = useReactiveVar(onlyUnreadVar);
  const onCheckUnread = (e: React.ChangeEvent<HTMLInputElement>) => {
    onlyUnreadVar(e.target.checked);
  }
  const onlyMyMessages = useReactiveVar(onlyMyMessagesVar);
  const onCheckMy = (e: React.ChangeEvent<HTMLInputElement>) => {
    onlyMyMessagesVar(e.target.checked);
  }

  useEffect(() => {
    if (!currentFolder) return
    if (foldersFilteredMap.get(currentFolder.id)?.unread != onlyUnread || foldersFilteredMap.get(currentFolder.id)?.my != onlyMyMessages) {
      foldersFilteredMap.set(currentFolder.id, {'unread': onlyUnread, 'my': onlyMyMessages})
      setNeedRefetchFolderChats(true)
    }
  }, [onlyUnread, onlyMyMessages, currentFolder?.id, foldersFilteredMap])

  return (
    <div className="ChatFolders">
      {folderTabs && folderTabs.length ? (
        <TabList tabs={folderTabs} activeTab={openTabIndex} onSwitchTab={(tabId: number) => { chatFolderVar(displayedFolders[tabId]); }} />
      ) : orderedFolderIds ? (
        <div className={buildClassName('tabs-placeholder')} />
      ) : null}
      <div className="chat-list__filter">
        <Checkbox
          label="Только непрочитанные"
          onChange={onCheckUnread}
          checked={onlyUnread}
          classes={{
            container: "chat-list__filter-checkbox",
            input: "chat-list__filter-checkbox-label",
          }}
        />
        <Checkbox
          label="Мои"
          onChange={onCheckMy}
          checked={onlyMyMessages}
          classes={{
            container: "chat-list__filter-checkbox",
            input: "chat-list__filter-checkbox-label",
          }}
        />
      </div>
      <ChatList
        folderType="folder"
        folderId={currentFolder?.id}
        noChatsText={lang('FilterNoChatsToDisplay')}
        isActive={true}
        needRefetch={needRefetchFolderChats}
        afterRefetch={() => setNeedRefetchFolderChats(false)}
      />
    </div>
  );
};

export default ChatFolders
