import { StoreObject } from '@apollo/client'
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'

export const insertOrderedChat = <T extends StoreObject>(chat: T, chats: T[], readField?: ReadFieldFunction): T[] => {
  const chatId = readField ? readField('user_id', chat) : chat.user_id
  const lastMessageId = readField ? readField('last_message_id', chat) : chat.last_message_id
  if (!lastMessageId) return chats

  // remove chat from array to avoid duplicates
  chats = readField
    ? chats.filter(c => readField('user_id', c) !== chatId)
    : chats.filter(c => c.user_id !== chatId)

  const afterIndex = readField
    ? chats.findIndex(c => lastMessageId < (readField('last_message_id', c) || 0))
    : chats.findIndex(c => lastMessageId < (c.last_message_id || 0))

  if (afterIndex >= 0) {
    chats.splice(afterIndex + 1, 0, chat)
  } else {
    chats.push(chat)
  }
  return chats
}