import { ApolloCache, InMemoryCache } from '@apollo/client'
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'
import {
  ChatTagsDocument,
  ChatTagsQuery,
  ChatTagsQueryVariables
} from '../schema'

export const addTagToChat = (userId: number | undefined, tagId: number | undefined, cache: InMemoryCache | ApolloCache<any>, readField: ReadFieldFunction) => {
  if (!userId || !tagId) return

  const chat = cache.readQuery<ChatTagsQuery, ChatTagsQueryVariables>({
    query: ChatTagsDocument,
    variables: {
      id: userId
    }
  })

  if (!chat || !chat.user) return

  const newTags = [...chat.user.tags]
  if (!chat.user.tags.find(t => t.tag_id === tagId && t.user_id === userId)) {
    newTags.push({
      __typename: 'user_tag_relations',
      user_id: userId,
      tag_id: tagId
    })
  }

  cache.writeQuery<ChatTagsQuery, ChatTagsQueryVariables>({
    query: ChatTagsDocument,
    data: {
      __typename: 'Query',
      user: {
        ...chat?.user,
        tags: newTags
      }
    }
  })
}