

import { InMemoryCache } from '@apollo/client'
import {
  ChatsDocument,
  ChatsQuery,
  ChatsQueryVariables,
} from '../schema'

export const removeChatFromFolder = (userId: number | undefined, tagId: number | undefined, cache: InMemoryCache) => {
  if (!userId) return

  const variables = {
    where: {
      tags: {
        tag_id: {
          _eq: tagId
        }
      }
    }
  }

  const chatCollection = cache.readQuery<ChatsQuery, ChatsQueryVariables>({
    query: ChatsDocument,
    variables
  })

  if (!chatCollection?.users?.length) return
  cache.writeQuery<ChatsQuery, ChatsQueryVariables>({
    query: ChatsDocument,
    variables,
    data: {
      __typename: 'Query',
      users: chatCollection.users.filter(u => u.id !== userId)
    },
  })
}

