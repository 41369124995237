import {
  useDeleteMessageSubscription,
} from '../graphql/schema'


/**
 * This hook listens to message deletions and updates cache
 */
const useListenToDeletedMessages = () => {
  useDeleteMessageSubscription({
    onData: async ({ data, client }) => {
      const cache = client.cache

      const deleteMessage = data.data?.deleteMessage
      if (!deleteMessage) return

      const normalizedId = cache.identify({ message_id: deleteMessage.message_id, __typename: 'messages', })
      cache.evict({
        id: normalizedId
      })
      cache.gc();
    }
  })
}

export default useListenToDeletedMessages