import { useCallback } from 'react'
import { Message } from 'telegram-typings'
import { useReactiveVar } from '@apollo/client';
import { currentChatIdVar, currentUserIdVar } from '../cache';
import { useSendMessagesMutation, MessagesDocument, MessagesQuery, MessagesQueryVariables } from '../graphql/schema';
import { ApiAttachment, ApiSticker } from '../api/types'

interface SendMessageParams {
    text?: string;
    entities?: any[] // entities: ApiMessageEntity[];
    replyingTo?: number;
    attachments?: ApiAttachment[]// attachment: ApiAttachment;
    sticker?: ApiSticker;
    gif?: any// gif: ApiVideo;
    poll?: any// poll: ApiNewPoll;
    serverTimeOffset?: number;
    scheduledAt?: number;
    isSilent?: boolean;
}

const useSendMessage = () => {
    const [sendMessageMutation] = useSendMessagesMutation()
    const chatId = useReactiveVar(currentChatIdVar)
    const senderId = useReactiveVar(currentUserIdVar)

    return useCallback(async(params: SendMessageParams) => {
        if (!chatId) return;
        console.log(params.sticker)

        return await sendMessageMutation({
            variables: {
                input: {
                    chatId,
                    text: params.text,
                    entities: params.entities,
                    replyTo: params.replyingTo,
                    disableNotification: params.isSilent,
                    files: params.attachments?.map(a => a.blob),
                }
            },
            optimisticResponse: {
                __typename: 'Mutation',
                sendMessages: [
                    {
                        __typename: 'messages',
                        message_id: Number.MAX_SAFE_INTEGER,
                        user_id: chatId,
                        staff_id: senderId,
                        //@ts-ignore
                        staff_editor_id: null,
                        created: Date.now() / 1000,
                        mess: {
                            chat: {
                                id: chatId,
                                type: 'private'
                            },
                            from: {
                                id: senderId
                            },
                            message_id: Number.MAX_SAFE_INTEGER,
                            date: Date.now() / 1000,
                            text: params.text,
                        } as Message,
                    }
                ]
            },
            update: (cache, { data }) => {
                console.log('DATA', data)
                if (!data) return

                const messages = data.sendMessages

                const chat = cache.readQuery<MessagesQuery, MessagesQueryVariables>({
                    query: MessagesDocument,
                    variables: {
                        where: {
                            user_id: {
                                _eq: messages[0].user_id!
                            }
                        }
                    }
                })

                const newMessages = messages.map(m => ({
                    ...m,
                    isOutgoing: true,
                    id: m.message_id,
                    content: {},
                    chatId: m.user_id!,
                    date: m.created!,
                }))

                console.log('writing query', chatId, data.sendMessages[0].message_id)
                cache.writeQuery<MessagesQuery, MessagesQueryVariables>({
                    query: MessagesDocument,
                    variables: {
                        where: {
                            user_id: {
                                _eq: messages[0].user_id!
                            }
                        }
                    },
                    data: {
                        __typename: 'Query',
                        ...chat,
                        messages: newMessages
                    }
                })
            },
            onError: (error) => {                  
                var text = 'Не удалось отправить сообщение\nОфициальная причина: ' + error
                alert(text)
            }
        })
    }, [chatId, senderId, sendMessageMutation])
}

export default useSendMessage
